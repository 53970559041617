<template>
  <div v-if="(list.length > 0)" class="paddingPagListagens flex flex-column lg:flex-row app-page-padding">
    <div class="destaquesLeft flex flex-column w-full lg:w-6 bg-white">
      <ListItemDestaqueLeft v-if="(destaquesLeft01 != null && destaqueSelecionado == 1)" :tipo="destaquesLeft01.tipo" :item="destaquesLeft01.dados" />
      <ListItemDestaqueLeft v-if="(destaquesLeft02 != null && destaqueSelecionado == 2)" :tipo="destaquesLeft02.tipo" :item="destaquesLeft02.dados" />
      <ListItemDestaqueLeft v-if="(destaquesLeft03 != null && destaqueSelecionado == 3)" :tipo="destaquesLeft03.tipo" :item="destaquesLeft03.dados" />
      <div class="flex mt-6">
        <div @click="mt_destaqueChange(1)" v-if="(destaquesLeft01 != null)" :class="'destaqueSelect' + (destaquesLeft01 != null && destaqueSelecionado == 1 ? ' selected' : '')"></div>
        <div @click="mt_destaqueChange(2)" v-if="(destaquesLeft02 != null)" :class="'destaqueSelect' + (destaquesLeft02 != null && destaqueSelecionado == 2 ? ' selected' : '')"></div>
        <div @click="mt_destaqueChange(3)" v-if="(destaquesLeft03 != null)" :class="'destaqueSelect' + (destaquesLeft03 != null && destaqueSelecionado == 3 ? ' selected' : '')"></div>
      </div>
    </div>
    <div class="hidden lg:flex flex-column flex-grow-0">
      <div style="width:20px;"></div>
    </div>
    <div class="destaquesRight flex flex-column w-full lg:w-6">
      <AppTitleX :titulo="fc_getText(null, 'emDestaque')" />
      <ListItemDestaqueRight v-for="(forItem, forIndex) in destaquesRight" :key="forIndex" :tipo="forItem.tipo" :item="forItem.dados" />
    </div>
  </div>
  <div v-if="(list.length == 0)" class="mt-8"></div>
</template>

<script>
import ListItemDestaqueLeft from '@/components/ListItemDestaqueLeft.vue';
import ListItemDestaqueRight from '@/components/ListItemDestaqueRight.vue';
export default {
  components: {
    ListItemDestaqueLeft,
    ListItemDestaqueRight,
  },
  data() {
    return {
      loaded: false,
      list: [],
      destaqueInterval: null,
      destaqueRodar: true,
      destaqueSelecionado: 1,
    };
  },
  props: {},
  computed: {
    destaquesLeft() {
      if (this.list.length > 3) {
        return this.list.slice(0, 3);
      } else {
        return this.list;
      }
    },
    destaquesLeft01() {
      if (this.list.length > 0) {
        return this.list[0];
      } else {
        return null;
      }
    },
    destaquesLeft02() {
      if (this.list.length > 1) {
        return this.list[1];
      } else {
        return null;
      }
    },
    destaquesLeft03() {
      if (this.list.length > 2) {
        return this.list[2];
      } else {
        return null;
      }
    },
    destaquesRight() {
      if (this.list.length > 3) {
        return this.list.slice(3, this.list.length);
      } else {
        return [];
      }
    },
  },
  watch: {},
  methods: {
    mt_start() {
      this.mt_loadData();
    },
    mt_loadData() {
      this.loaded = false;
      this.fc_fetch({ endpoint: 'Destaques/', method: 'GET', success: this.mt_loadData_success });
    },
    mt_loadData_success(response) {
      if (response != null && response.list != null) {
        this.list = response.list;
        this.mt_destaqueIntervalSet();
      }
      this.loaded = true;
    },
    mt_destaqueChange(destaque) {
      this.destaqueRodar = false;
      this.destaqueSelecionado = destaque;
    },
    mt_destaqueIntervalSet() {
      if (this.destaqueInterval == null) { this.destaqueInterval = setInterval(() => { this.mt_destaqueRodar(); }, 5000); }
    },
    mt_destaqueIntervalClear() {
      if (this.destaqueInterval != null) { clearInterval(this.destaqueInterval); this.destaqueInterval = null; }
    },
    mt_destaqueRodar() {
      if (this.destaqueRodar) {
        let destaqueSelecionado = this.destaqueSelecionado + 1;
        if (destaqueSelecionado == 2 && this.destaquesLeft02 != null) {
          this.destaqueSelecionado = destaqueSelecionado;
        } else if (destaqueSelecionado == 3 && this.destaquesLeft03 != null) {
          this.destaqueSelecionado = destaqueSelecionado;
        } else {
          this.destaqueSelecionado = 1;
        }
      }
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {
    this.mt_destaqueIntervalClear();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.destaquesLeft {
  position: relative;
  top: -60px;
}
@media screen and (max-height:600px) {
  .destaquesLeft {
    position: relative;
    top: -25px;
  }
}
.destaquesRight {
  position: relative;
  top: 60px;
}

@media screen and (max-width:991px) {
  .destaquesLeft {
    top: 0px;
  }
  .paddingPagListagens {
    padding-top: 9vw;
  }
}
@media screen and (max-width:576px) {
  .destaquesLeft {
    top: 0px;
  }
  .paddingPagListagens {
    padding-top: 13.5vw;
  }
}
.destaqueSelect {
  width: 100px;
  height: 4px;
  background-color: var(--rsa-gray-02);
  margin-right: 10px;
  cursor: pointer;
}
.destaqueSelect:hover {
  background-color: var(--text-color);
}
.destaqueSelect.selected {
  background-color: var(--rsa-color);
}
</style>
