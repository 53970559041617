<template>
  <div class="search searching-modal-padding pesquisa-modal mt-6">
    <div class="mb-4">
      <AppTitleX :titulo="fc_getText(null, 'tituloPesquisa')" />
      <span class="pesquisa-padding p-input-icon-right flex flex-column">
        <i class="pi pi-search" />
        <InputText @change="mt_searchData()" id="searchText" class='input-caixa' v-model="searchText" :placeholder="fc_getText(null, 'placeholderPesquisa')" />
      </span>
      <div v-if="(!resultsLoaded && searching)"><AppSpinner :fill="'var(--rsa-color)'" :stroke="'var(--rsa-gray-01)'" /></div>
      <div v-if="(resultsLoaded)" class="pesquisa-padding searched-results pt-2 mt-4">
        <div class="searched-label rsa-font-montserrat-bold text-base md:text-lg lg:text-xl xl:text-2xl mt-2 mb-4" v-html="search"></div>
        <div class="mt-4" v-if="(membros.length > 0)">
          <div class="flex w-full align-items-center mb-1">
            <div class="section-linha flex mr-2"></div>
            <div class="section flex text-base md:text-base lg:text-lg xl:text-xl rsa-font-montserrat-bold" v-html="this.fc_getText(null, 'equipa', { title: true })"></div>
          </div>
          <div @click="mt_redirect('membro',forItem)" class="searchResult-caixa" v-for="(forItem, forIndex) in membros" :key="forIndex" :item="forItem">
            <div class="p-2">
              <div class="rsa-font-montserrat-semibold" v-html="forItem.nome"></div>
              <div class="text-xs" v-html="forItem.categorias"></div>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="(areas.length > 0)">
          <div class="flex w-full align-items-center mb-1">
            <div class="section-linha flex mr-2"></div>
            <div class="section flex text-base md:text-base lg:text-lg xl:text-xl rsa-font-montserrat-bold" v-html="this.fc_getText(null, 'areas', { title: true })"></div>
          </div>
          <div @click="mt_redirect('area',forItem)" class="searchResult-caixa" v-for="(forItem, forIndex) in areas" :key="forIndex" :item="forItem">
            <div class="p-2">
              <div class="rsa-font-montserrat-semibold" v-html="forItem.descritivoMenu"></div>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="(redes.length > 0)">
          <div class="flex w-full align-items-center mb-1">
            <div class="section-linha flex mr-2"></div>
            <div class="section flex text-base md:text-base lg:text-lg xl:text-xl rsa-font-montserrat-bold" v-html="this.fc_getText(null, 'rede', { title: true })"></div>
          </div>
          <div @click="mt_redirect('rede',forItem)" class="searchResult-caixa" v-for="(forItem, forIndex) in redes" :key="forIndex" :item="forItem">
            <div class="p-2">
              <div class="rsa-font-montserrat-semibold" v-html="forItem.descritivoMenu"></div>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="(noticias.length > 0)">
          <div class="flex w-full align-items-center mb-1">
            <div class="section-linha flex mr-2"></div>
            <div class="section flex text-base md:text-base lg:text-lg xl:text-xl rsa-font-montserrat-bold" v-html="this.fc_getText(null, 'noticias', { title: true })"></div>
          </div>
          <div @click="mt_redirect('noticia',forItem)" class="searchResult-caixa" v-for="(forItem, forIndex) in noticias" :key="forIndex" :item="forItem">
            <div class="p-2">
              <div class="rsa-font-montserrat-semibold" v-html="forItem.tituloDestaque"></div>
              <div class="text-xs" v-html="forItem.subtituloDestaque"></div>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="(rsolNoticias.length > 0)">
          <div class="flex w-full align-items-center mb-1">
            <div class="section-linha flex mr-2"></div>
            <div class="section flex text-base md:text-base lg:text-lg xl:text-xl rsa-font-montserrat-bold" v-html="this.fc_getText(null, 'rsolnoticias', { title: true })"></div>
          </div>
          <div @click="mt_redirect('rsolnoticia',forItem)" class="searchResult-caixa" v-for="(forItem, forIndex) in rsolNoticias" :key="forIndex" :item="forItem">
            <div class="p-2">
              <div class="rsa-font-montserrat-semibold" v-html="forItem.tituloDestaque"></div>
              <div class="text-xs" v-html="forItem.subtituloDestaque"></div>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="(publicacoes.length > 0)">
          <div class="flex w-full align-items-center mb-1">
            <div class="section-linha flex mr-2"></div>
            <div class="section flex text-base md:text-base lg:text-lg xl:text-xl rsa-font-montserrat-bold" v-html="this.fc_getText(null, 'publicacoes', { title: true })"></div>
          </div>
          <div @click="mt_redirect('publicacao',forItem)" class="searchResult-caixa" v-for="(forItem, forIndex) in publicacoes" :key="forIndex" :item="forItem">
            <div class="p-2">
              <div class="rsa-font-montserrat-semibold" v-html="forItem.titulo"></div>
              <div class="text-xs" v-html="forItem.subtitulo"></div>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="(eventos.length > 0)">
          <div class="flex w-full align-items-center mb-1">
            <div class="section-linha flex mr-2"></div>
            <div class="section flex text-base md:text-base lg:text-lg xl:text-xl rsa-font-montserrat-bold" v-html="this.fc_getText(null, 'eventos', { title: true })"></div>
          </div>
          <div @click="mt_redirect('evento',forItem)" class="searchResult-caixa" v-for="(forItem, forIndex) in eventos" :key="forIndex" :item="forItem">
            <div class="p-2">
              <div class="rsa-font-montserrat-semibold" v-html="forItem.tituloDestaque"></div>
              <div class="text-xs" v-html="forItem.subtituloDestaque"></div>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="(videos.length > 0)">
          <div class="flex w-full align-items-center mb-1">
            <div class="section-linha flex mr-2"></div>
            <div class="section flex text-base md:text-base lg:text-lg xl:text-xl rsa-font-montserrat-bold" v-html="this.fc_getText(null, 'videos', { title: true })"></div>
          </div>
          <div @click="mt_redirect('video',forItem)" class="searchResult-caixa" v-for="(forItem, forIndex) in videos" :key="forIndex" :item="forItem">
            <div class="p-2">
              <div class="rsa-font-montserrat-semibold" v-html="forItem.titulo"></div>
              <div class="text-xs" v-html="forItem.subtitulo"></div>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="(paginasRelacionadas.length > 0)">
          <div class="flex w-full align-items-center mb-1">
            <div class="section-linha flex mr-2"></div>
            <div class="section flex text-base md:text-base lg:text-lg xl:text-xl rsa-font-montserrat-bold" v-html="this.fc_getText(null, 'paginasRelacionadas', { title: true })"></div>
          </div>
          <div @click="mt_redirect('pagina',forItem)" class="searchResult-caixa" v-for="(forItem, forIndex) in paginasRelacionadas" :key="forIndex" :item="forItem">
            <div class="p-2">
              <div class="rsa-font-montserrat-semibold" v-html="forItem.nome"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['dialogRef'],
  components: {},
  data() {
    return {
      searchText: '',
      resultsLoaded: false,
      searching: false,
      nenhumResultado: false,
      search: '',
      membros: [],
      areas: [],
      redes: [],
      publicacoes: [],
      noticias: [],
      rsolNoticias: [],
      eventos: [],
      videos: [],
      paginasRelacionadas: [],
      pesquisaTags: []
    };
  },
  props: {},
  computed: {
    titleColor() {
      if (this.color == null) {
        return 'var(--text-color)';
      } else {
        return this.color;
      }
    },
  },
  watch: {},
  methods: {
    mt_searchData(){
      this.cleanArrays();
      if (this.searchText.trim() != '') {
        this.searching = true;
        this.resultsLoaded = false;
        let promises = [];
        let query = '?searchText=' + this.searchText;

        promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'PesquisaTags/' + query, method: 'GET', success: (response) => { this.pesquisaTags = response.list; resolve(); }, error: reject }); }));
        promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'PesquisaMembro/' + query, method: 'GET', success: (response) => { this.membros = response.list; resolve(); }, error: reject }); }));
        promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'PesquisaArea/' + query, method: 'GET', success: (response) => { this.areas = response.list; resolve(); }, error: reject }); }));
        promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'PesquisaRede/' + query, method: 'GET', success: (response) => { this.redes = response.list; resolve(); }, error: reject }); }));
        promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'PesquisaNoticia/' + query, method: 'GET', success: (response) => { this.noticias = response.list; resolve(); }, error: reject }); }));
        promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'PesquisaRsolNoticia/' + query, method: 'GET', success: (response) => { this.rsolNoticias = response.list; resolve(); }, error: reject }); }));
        promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'PesquisaPublicacao/' + query, method: 'GET', success: (response) => { this.publicacoes = response.list; resolve(); }, error: reject }); }));
        promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'PesquisaEvento/' + query, method: 'GET', success: (response) => { this.eventos = response.list; resolve(); }, error: reject }); }));
        promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'PesquisaVideo/' + query, method: 'GET', success: (response) => { this.videos = response.list; resolve(); }, error: reject }); }));
        Promise.all(promises).then(() => { this.mt_searched(); }).catch((e) => { this.fc_printErrors(e); });
      } else {
        this.search = '';
        this.searching = false;
        this.resultsLoaded = false;
      }
    },
    mt_searched() {
      this.nenhumResultado = false;
      this.paginasRelacionadas.splice(0,this.paginasRelacionadas.length);

      this.pesquisaTags.forEach((item,index) => {
        let objeto = null;
        let indice = null;
        if (item.idTabela == null) {
          objeto = { nome: this.fc_getText(null, item.menu, { title: true }) , menu:item.menu}
          this.paginasRelacionadas.push(objeto);
        } else {
          if (item.menu == "noticia" && item.publicacao != null) {
            objeto = { tituloDestaque: item.publicacao.titulo, subtituloDestaque: item.publicacao.subtitulo , id: item.idTabela };
            indice = this.noticias.find(x => x.id == item.idTabela);
            if (indice == null && item.publicacao.ativo) { this.noticias.push(objeto); }
          } else if (item.menu == "video" && item.publicacao != null) {
            objeto = { titulo: item.publicacao.titulo, subtitulo: item.publicacao.subtitulo , id: item.idTabela };
            indice = this.videos.find(x => x.id == item.idTabela);
            if (indice == null && item.publicacao.ativo) { this.videos.push(objeto); }
          } else if (item.menu == "evento" && item.publicacao != null) {
            objeto = { tituloDestaque: item.publicacao.titulo, subtituloDestaque: item.publicacao.subtitulo , id: item.idTabela };
            indice = this.eventos.find(x => x.id == item.idTabela);
            if (indice == null && item.publicacao.ativo) { this.eventos.push(objeto); }
          } else if ((item.menu == "ebook" || item.menu == "legalAlert" || item.menu == "outraPublicacao" || item.menu == "livro" || item.menu == "mewsletter") && item.publicacao != null ) {
            objeto = { titulo: item.publicacao.titulo, subtitulo: item.publicacao.subtitulo , id: item.idTabela , visualizacao: item.publicacao.visualizacao, anexo: '', anexoNome: '', linkPublicacaoExterna: '', linkPlayer: '' };
            if (item.publicacao.visualizacao == 2) {
              objeto.linkPublicacaoExterna = item.publicacao.linkPublicacaoExterna;
            } else if (item.publicacao.visualizacao == 3) {
              objeto.anexo = item.publicacao.anexo;
              objeto.anexoNome = item.publicacao.anexoNome.toUpperCase();
            } else if (item.publicacao.visualizacao == 4) {
              objeto.linkPlayer = item.publicacao.linkPlayer;
            }
            indice = this.publicacoes.find(x => x.id == item.idTabela);
            if (indice == null && item.publicacao.ativo) { this.publicacoes.push(objeto); }
          } else if (item.menu == "rsolnoticia" && item.publicacao != null) {
            objeto = { tituloDestaque: item.publicacao.titulo, subtituloDestaque: item.publicacao.subtitulo , id: item.idTabela };
            indice = this.rsolNoticias.find(x => x.id == item.idTabela);
            if (indice == null && item.publicacao.ativo) { this.rsolNoticias.push(objeto); }
          } else if (item.menu == "membro" && item.membro != null) {
            objeto = { nome: item.membro.nome, categorias: item.membro.categorias , id: item.idTabela };
            indice = this.membros.find(x => x.id == item.idTabela);
            if (indice == null && item.membro.ativo) { this.membros.push(objeto); }
          } else if (item.menu == "area" && item.area != null) {
            objeto = { descritivoMenu: item.area.descritivoMenu, titulo: item.area.titulo , id: item.idTabela };
            indice = this.areas.find(x => x.id == item.idTabela);
            if (indice == null && item.area.ativo) { this.areas.push(objeto); }
          } else if (item.menu == "rede" && item.rede != null) {
            objeto = { descritivoMenu: item.rede.descritivoMenu, titulo: item.rede.titulo , id: item.idTabela };
            indice = this.redes.find(x => x.id == item.idTabela);
            if (indice == null && item.rede.ativo) { this.redes.push(objeto); }
          }
        }
      })

      this.membros.forEach((item,index) => {
        item.nome = this.fc_replace(item.nome,this.searchText);
      })
      this.areas.forEach((item,index) => {
        item.descritivoMenu = this.fc_replace(item.descritivoMenu,this.searchText);
      })
      this.redes.forEach((item,index) => {
        item.descritivoMenu = this.fc_replace(item.descritivoMenu,this.searchText);
      })
      this.noticias.forEach((item,index) => {
        item.tituloDestaque = this.fc_replace(item.tituloDestaque,this.searchText);
      })
      this.publicacoes.forEach((item,index) => {
        item.titulo = this.fc_replace(item.titulo,this.searchText);
      })
      this.eventos.forEach((item,index) => {
        item.tituloDestaque = this.fc_replace(item.tituloDestaque,this.searchText);
      })
      this.videos.forEach((item,index) => {
        item.titulo = this.fc_replace(item.titulo,this.searchText);
      })
      this.rsolNoticias.forEach((item,index) => {
        item.tituloDestaque = this.fc_replace(item.tituloDestaque,this.searchText);
      })

      if (this.paginasRelacionadas.length == 0 && this.membros.length == 0 && this.areas.length == 0 && this.redes.length == 0 && this.noticias.length == 0 && this.publicacoes.length == 0 && this.eventos.length == 0 && this.videos.length == 0 && this.rsolNoticias.length == 0) {
        this.nenhumResultado = true;
        this.search = this.fc_getText(null, 'zeroResultadosPesquisa');
      } else {
        this.search = this.fc_getText(null, 'labelResultadoPesquisa') + " '" + this.searchText + "':";
      }
      this.resultsLoaded = true;
      this.searching = false;
    },
    mt_redirect(seccao,item) {
      this.dialogRef.close();

      if (seccao == 'membro') {
        this.fc_goRoute('membro', { id: item.id })
      } else if (seccao == 'area') {
        this.fc_goRoute('area', { id: item.id })
      } else if (seccao == 'rede') {
        this.fc_goRoute('rede', { id: item.id })
      } else if (seccao == 'noticia') {
        this.fc_goRoute('noticia', { id: item.id })
      } else if (seccao == 'publicacao') {
        if (item.visualizacao == 2) {
          window.open(item.linkPublicacaoExterna, '_blank', 'noreferrer');
        } else if (item.visualizacao == 3) {
          this.fc_dialogPdf(item.anexo, item.anexoNome.toUpperCase());
        } else if (item.visualizacao == 4) {
          this.fc_dialogPlayer(item.titulo, this.fc_removeHTML(item.linkPlayer));
        } else {
          this.fc_goRoute('publicacao', { id: item.id })
        }
      } else if (seccao == 'evento') {
        this.fc_goRoute('evento', { id: item.id })
      } else if (seccao == 'video') {
        this.fc_goRoute('video', { id: item.id })
      } else if (seccao == 'rsolnoticia') {
        this.fc_goRoute('rsolnoticia', { id: item.id })
      } else if (seccao == 'pagina') {
        this.fc_goRoute(item.menu);
      }
    },
    fc_removeAccentuation(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    },
    fc_replace(string,search) {
      let stringFinal = this.fc_removeHTML(string);
      const normalizeString = this.fc_removeAccentuation(stringFinal);
      const normalizeSearch = this.fc_removeAccentuation(search);
      const regex = new RegExp(normalizeSearch,'i')
      const resultadoMatch = normalizeString.match(regex);
      let textoFinal = normalizeString;
      if (resultadoMatch != null) {
        const re_replace = new RegExp(resultadoMatch[0], 'gi');
        textoFinal = textoFinal.replace(re_replace, `<span class='matchCor'>${resultadoMatch[0]}</span>`)
      }
      return textoFinal;
    },
    cleanArrays(){
      this.membros.splice(0,this.membros.length);
      this.areas.splice(0,this.areas.length);
      this.redes.splice(0,this.redes.length);
      this.publicacoes.splice(0,this.publicacoes.length);
      this.noticias.splice(0,this.noticias.length);
      this.rsolNoticias.splice(0,this.rsolNoticias.length);
      this.eventos.splice(0,this.eventos.length);
      this.videos.splice(0,this.videos.length);
      this.paginasRelacionadas.splice(0,this.paginasRelacionadas.length);
      this.pesquisaTags.splice(0,this.pesquisaTags.length);
    }
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.searching-modal-padding {
  padding: 0vw 16vw 8vw 8vw;
}
.pesquisa-padding {
  padding-left: 8vw;
}
.input-caixa {
  background-color: var(--rsa-color);
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid white;
  color: white !important;
}
.input-caixa::placeholder {
  color: white !important;
}
.searched-results,
.searched-label {
  color: black;
}
.searchResult-caixa {
  border-bottom: 1px solid #009bd6;
  cursor: pointer;
}
.searchResult-caixa:hover {
  background-color: #00a0db;
}
.matchCor {
  background-color: #cbd3da;
}
.section {
  color: black;
}
.section-linha {
  width: 1vw;
  border-bottom: solid 1px v-bind('titleColor');
}
</style>
