<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div :class="(item.imagem != null ? 'app-page-margin-withImage' : 'app-page-margin-withoutImage app-page-padding')">
      <AppImagem v-if="(item.imagem != null)" :imagem="item.imagem" :titulo="item.tituloImagem" :subtitulo="item.subtituloImagem" />
      <AppCitacao :titulo="item.titulo" :conteudo="item.conteudo" :class="(item.imagem != null ? 'paddingPagConteudo-withImage mt-6' : 'paddingPagConteudo mt-6')" />
      <div v-if="(item.noticias != null && item.noticias.length > 0)" :class="(item.imagem != null ? 'app-page-padding': '')">
        <ConhecimentoNoticias :list="item.noticias" />
      </div>
      <div v-if="(item.eventos != null && item.eventos.length > 0)" :class="(item.imagem != null ? 'app-page-padding': '')">
        <ConhecimentoEventos :list="item.eventos" />
      </div>
      <div v-if="(item.videos != null && item.videos.length > 0)" :class="(item.imagem != null ? 'app-page-padding': '')">
        <ConhecimentoVideos :list="item.videos" />
      </div>
      <div v-if="(item.publicacoes != null && item.publicacoes.length > 0)" :class="(item.imagem != null ? 'app-page-padding': '')">
        <ConhecimentoPublicacoes :list="item.publicacoes" />
      </div>
    </div>
  </div>
</template>

<script>
import ConhecimentoNoticias from '@/components/ConhecimentoNoticias.vue';
import ConhecimentoEventos from '@/components/ConhecimentoEventos.vue';
import ConhecimentoVideos from '@/components/ConhecimentoVideos.vue';
import ConhecimentoPublicacoes from '@/components/ConhecimentoPublicacoes.vue';
export default {
  components: {
    ConhecimentoNoticias,
    ConhecimentoEventos,
    ConhecimentoVideos,
    ConhecimentoPublicacoes,
  },
  data() {
    return {
      loaded: false,
      item: {},
      params: {
        rows: 4,
      },
    };
  },
  props: {},
  computed: {},
  methods: {
    mt_start() {
      this.mt_loadData();
    },
    mt_loadData() {
      this.loaded = false;
      const larguraTela = window.innerWidth;
      if (larguraTela < 1400) {
        this.params.rows = 4;
      } else if (larguraTela >= 1400 && larguraTela <= 1770){
        this.params.rows = 3;
      } else if (larguraTela > 1770){
        this.params.rows = 4;
      }
      this.fc_fetch({ endpoint: 'ConhecimentoPagina/?tableParams=' + encodeURIComponent(JSON.stringify(this.params)), method: 'GET', success: this.mt_loadData_success, error: () => { this.mt_goBack(); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.item != null) {
        this.item = response.item;
        this.loaded = true;
      } else {
        this.mt_goBack();
      }
    },
    mt_goBack() {
      this.fc_goRoute('home');
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 992px) {
  .paddingPagConteudo-withImage {
    padding: 0vw 8vw 4vw 6vw;
  }
}
@media screen and (min-width: 992px) {
  .paddingPagConteudo-withImage {
    padding: 0vw 8vw 4vw 315px;
  }
}

.caixa {
  cursor: pointer;
}
.caixa:hover {
  background-color: var(--rsa-color-06);
}
</style>
