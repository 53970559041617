import PdfDialog from '@/views/PdfDialog.vue';
import PlayerDialog from '@/views/PlayerDialog.vue';
import NewsletterBt from '@/components/NewsletterBt.vue';
import NewsletterForm from '@/components/NewsletterForm.vue';
export default {
  components: {
    PdfDialog,
    NewsletterBt,
    NewsletterForm,
  },
  methods: {
    fc_getDev() {
      if (window.location.hostname == 'localhost' && window.location.port == '8081') {
        return true;
      } else if (window.location.hostname == 'localhost') {
        return true;
      } else {
        return false;
      }
    },
    fc_getAppBase() {
      return '/';
      // return '/testes/rsa/';
    },
    fc_getApiHost() {
      if (window.location.hostname == 'localhost' && window.location.port == '8081') {
        return 'http://localhost:8091/';
      } else if (window.location.hostname == 'localhost') {
        return 'http://localhost/api/';
      } else if (window.location.hostname.includes('10.0.64.12')) {
        return 'http://10.0.64.12/testes/rsa/api/';
      } else if (window.location.hostname.includes('95.136.11.200')) {
        return 'http://95.136.11.200/testes/rsa/api/';
      } else if (window.location.hostname.includes('ibasejuridico.com')) {
        if (window.location.hostname.includes('www.')) {
          return 'https://www.ibasejuridico.com' + this.fc_getAppBase() + 'api/';
        } else {
          return 'https://ibasejuridico.com' + this.fc_getAppBase() + 'api/';
        }
      } else {
        if (window.location.hostname.includes('www.')) {
          return 'https://www.rsa-lp.com' + this.fc_getAppBase() + 'api/';
        } else {
          return 'https://rsa-lp.com' + this.fc_getAppBase() + 'api/';
        }
      }
    },
    fc_getUrlParam(param) {
      const queryString = window.location.href.split('?')[1];
      if (queryString) {
        const params = queryString.split('&');
        for (let i = 0; i < params.length; i++) {
          const pair = params[i].split('=');
          if (decodeURIComponent(pair[0]) == param) {
            return decodeURIComponent(pair[1]);
          }
        }
      }
      return null;
    },
    fc_openModal(options) {
      this.$dialog.open(options.component, {
        props: {
          modal: (options.modal == null ? true : options.modal),
          closable: (options.closable == null ? true : options.closable),
          dismissableMask: (options.dismissableMask == null ? false : options.dismissableMask),
          position: (options.position == null ? 'center' : options.position),
          maximizable: (options.maximizable == null ? false : options.maximizable),
          draggable: (options.draggable == null ? false : options.draggable),
          header: options.header,
          showHeader: (options.showHeader == null ? true : options.showHeader),
          style: (options.size == null || options.size == 'big' ? { width: '90vw' } : { width: '30vw' }),
          breakpoints: (options.size == null || options.size == 'big' ? null : { '1100px': '40vw', '960px': '50vw', '640px': '60vw', '440px': '80vw', }),
          contentClass: options.contentClass,
        },
        data: options.data,
        onClose: (closeOptions) => {
          if (options.onClose != null) {
            options.onClose();
          }
        }
      });
    },
    fc_confirmDialog(options) {
      this.$confirm.require({
        header: options.header,
        message: options.message,
        icon: options.icon,
        acceptClass: options.acceptClass,
        rejectClass: 'p-button-secondary',
        accept: () => {
          if (options.accept != null) {
            options.accept(options.data);
          }
        },
        reject: () => {
          if (options.reject != null) {
            options.reject(options.data);
          }
        }
      });
    },
    fc_go() {
      this.$router.go();
    },
    fc_goBack() {
      this.$router.go(-1);
    },
    fc_goForward() {
      this.$router.go(1);
    },
    fc_goRoute(name, params) {
      this.$router.push({ name: name, params: params });
    },
    fc_dialogPdf(file, title, event) {
      if (event != null) { event.stopPropagation(); }
      this.fc_openModal({ component: PdfDialog, dismissableMask: true, header: title, size: 'big', position: 'top', data: { pdf: file } });
    },
    fc_dialogPlayer(title, link, event) {
      if (event != null) { event.stopPropagation(); }
      this.fc_openModal({ component: PlayerDialog, dismissableMask: true, header: this.fc_removeHTML(title), size: 'big', position: 'top', data: { player: link  } });
    },
    fc_dialogNewsletterBt() {
      this.fc_openModal({ component: NewsletterBt, size: 'small', dismissableMask: true, maximizable: false, header: ' ', position: 'top', contentClass: 'dialogComp-newsletterBt' });
    },
    fc_dialogNewsletterForm() {
      this.fc_openModal({ component: NewsletterForm, size: 'big', dismissableMask: true, maximizable: true, header: this.fc_getText(null, 'newsletterSubscrever', { upper: true }), position: 'center', contentClass: 'dialogComp-newsletter' });
    },
    fc_getLang() {
      if (localStorage.lang != null) { return localStorage.lang; }
      else { return 'pt'; }
    },
    fc_setLang() {
      localStorage.lang = this.$store.state.lang;
    },
    fc_getIconSeverity(severity) {
      switch (severity) {
        case 'success': return 'pi-check-circle';
        case 'info': return 'pi-info-circle';
        case 'warn': return 'pi-exclamation-triangle';
        case 'error': return 'pi-times-circle';
        default: return 'pi-exclamation-circle';
      }
    },
    fc_getListOption(list, id) {
      let obj = list.find(x => x.id == id);
      if (obj != null) { return obj; }
      else { return null; }
    },
    fc_getText(dictionary, code, options = {}, param01 = null, param01_options = null, param02 = null, param02_options = null, param03 = null, param03_options = null) {
      let text = null;
      if (dictionary == null) { text = this.$store.state.dictionary.find(x => x.code == code); }
      else { text = dictionary.find(x => x.code == code); }

      if (text == null) {
        return '';
      } else {
        let result = text.text;

        if (options.lower || options.upper || options.title || options.first) {
          result = result.toLowerCase();
        }

        if (options.upper) {
          result = result.toUpperCase();
        } else if (options.title) {
          result = result.split(' ').map(x => ((/^(a|o|as|os|e|de|da|do|das|dos|na|no|nas|nos|sua|seu|suas|seus|por|pela|pelo|pelas|pelos|aos|em|ou|com|and|or)$/i).test(x) ? x : (x.charAt(0).toUpperCase() + x.substring(1)))).join(' ');
          result = result.split('<br/>').map(x => ((/^(a|o|as|os|e|de|da|do|das|dos|na|no|nas|nos|sua|seu|suas|seus|por|pela|pelo|pelas|pelos|aos|em|ou|com|and|or)$/i).test(x) ? x : (x.charAt(0).toUpperCase() + x.substring(1)))).join('<br/>');
        } else if (options.first ) {
          result = this.fc_capitalizeFirstWord(result);
        }

        if (param01 != null && !param01.startsWith('{{') && !param01.endsWith('}}')) { param01 = this.fc_getText(dictionary, param01, (param01_options == null ? options : param01_options)); }
        else if (param01 != null) { param01 = param01.replaceAll('{{', '').replaceAll('}}', ''); }
        if (param02 != null && !param02.startsWith('{{') && !param02.endsWith('}}')) { param02 = this.fc_getText(dictionary, param02, (param02_options == null ? options : param02_options)); }
        else if (param02 != null) { param02 = param02.replaceAll('{{', '').replaceAll('}}', ''); }
        if (param03 != null && !param03.startsWith('{{') && !param03.endsWith('}}')) { param03 = this.fc_getText(dictionary, param03, (param03_options == null ? options : param03_options)); }
        else if (param03 != null) { param03 = param03.replaceAll('{{', '').replaceAll('}}', ''); }

        result = result.replaceAll('{{param01}}', param01).replaceAll('{{param02}}', param02).replaceAll('{{param03}}', param03);

        return result;
      }
    },
    fc_capitalizeFirstWord(text) {
      let result = text;

      let frases = result.split(/(?<=[.!?])\s+/);
      result = frases.map((frase) => {
        return frase.replace(/^\w{1}/, (letra) => {
          return letra.toUpperCase()
        })
      }).join(' ');

      frases = result.split(/(?<=[.!?])<br\/>+/);
      result = frases.map((frase) => {
        return frase.replace(/^\w{1}/, (letra) => {
          return letra.toUpperCase()
        })
      }).join('<br/>');

      return result;
    },
    fc_validateNic(value) {
      if (value === undefined || value == null || value.length <= 0) {
        return true;
      } else {
        return new RegExp('^[0,1,2,3,4,5,6,7,8,9]{1,1}[0-9]{4,9}$').test(value);
      }
    },
    fc_validateNifSingulares(value) {
      if (value === undefined || value == null || value.length <= 0) {
        return true;
      } else {
        return new RegExp('^[1,2,3]{1,1}[0-9]{8,8}$').test(value);
      }
    },
    fc_validateNifColetivos(value) {
      if (value === undefined || value == null || value.length <= 0) {
        return true;
      } else {
        return new RegExp('^[1,2,3,5,6,7,8,9]{1,1}[0-9]{8,8}$').test(value);
      }
    },
    fc_validateTelemovel(value) {
      if (value === undefined || value == null || value.length <= 0) {
        return true;
      } else {
        return new RegExp('^[9,2]{1,1}[0-9]{8,8}$').test(value);
      }
    },
    fc_validateCodigoPostal(value) {
      if (value === undefined || value == null || value.length <= 0) {
        return true;
      } else {
        return new RegExp('^[0-9]{4,4}[-]{1,1}[0-9]{3,3}$').test(value);
      }
    },
    fc_validateIban(value) {
      if (value === undefined || value == null || value.length <= 0) {
        return true;
      } else {
        return new RegExp('^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$').test(value);
      }
    },
    fc_validateAlphanumeric(value) {
      if (value === undefined || value == null || value.length <= 0) {
        return true;
      } else {
        return new RegExp('^[a-zA-Z0-9]*$').test(value);
      }
    },
    fc_formatMoney(input) {
      let result = '';
      if (input != null && !isNaN(input)) {
        let n = 2, x = 3, s = ' ', c = ',', i = '€';
        let regex = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
        result = input.toFixed(Math.max(0, ~~n));
        result = (c ? result.replace('.', c) : input).replace(new RegExp(regex, 'g'), '$&' + (s || ',')) + ' ' + i;
      }
      return result;
    },
    fc_formatNumber(input) {
      let result = '';
      if (input != null && !isNaN(input)) {
        let n = 0, x = 3, s = '.', c = ',', i = '';
        let regex = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
        result = input.toFixed(Math.max(0, ~~n));
        result = (c ? result.replace('.', c) : input).replace(new RegExp(regex, 'g'), '$&' + (s || ',')) + ' ' + i;
      }
      return result;
    },
    fc_formatDateTime(input) {
      let result = '';
      if (input != null) {
        result = this.$dayjs(input).format('DD.MM.YYYY HH:mm:ss');
      }
      return result;
    },
    fc_formatDateTimeBr(input) {
      let result = '';
      if (input != null) {
        result = this.$dayjs(input).format('DD.MM.YYYY HH:mm:ss').replace(' ', '<br/>');
      }
      return result;
    },
    fc_formatDate(input) {
      let result = '';
      if (input != null) {
        result = this.$dayjs(input).format('DD.MM.YYYY');
      }
      return result;
    },
    fc_formatTime(input) {
      let result = '';
      if (input != null) {
        result = this.$dayjs(input).format('HH:mm:ss');
      }
      return result;
    },
    fc_formatTimeMin(input) {
      let result = '';
      if (input != null) {
        result = this.$dayjs(input).format('HH:mm');
      }
      return result;
    },
    fc_formatDay(input) {
      let result = '';
      if (input != null) {
        result = this.$dayjs(input).format('DD');
      }
      return result;
    },
    fc_formatMonth(input) {
      let result = '';
      if (input != null) {
        result = this.$dayjs(input).format('MM');
      }
      return result;
    },
    fc_formatMonthDesc(input) {
      let result = '';
      if (input != null) {
        result = this.$store.state.dictionary_primevue.monthNames[Number(this.$dayjs(input).format('MM')) - 1];
      }
      return result;
    },
    fc_formatYear(input) {
      let result = '';
      if (input != null) {
        result = this.$dayjs(input).format('YYYY');
      }
      return result;
    },
    fc_formatZeros(num, size) {
      let result = '';
      if (num != null && !isNaN(num)) {
        let s = num + '';
        while (s.length < size) s = '0' + s;
        result = s;
      }
      return result;
    },
    fc_fetch(params) {
      const options = {
        method: params.method,
        headers: new Headers(),
        body: (params.body == null ? null : (!params.formData ? JSON.stringify(params.body) : params.body))
      };

      options.headers.append('Lang', this.$store.state.lang);
      options.headers.append('Accept', 'application/json');
      if (!params.formData) { options.headers.append('Content-Type', 'application/json'); }

      fetch(this.fc_getApiHost() + params.endpoint, options)
        .then((response) => {
          if (!response.ok) {
            response.text().then(x => {
              let json = x.length ? JSON.parse(x) : null;
              this.fc_fetchError(json, params.error);
            });
          } else {
            response.text().then(x => {
              let json = x.length ? JSON.parse(x) : {};
              this.fc_fetchSuccess(json, params.success);
            });
          }
        })
        .catch((error) => {
          this.fc_fetchError(null, params.error, error);
        });
    },
    fc_fetchSuccess(response, callback) {
      if (response.errors && response.errorsList && response.errorsList.length > 0) { this.fc_fetchErrors(response.errorsList) }
      if (response.messages && response.messagesList && response.messagesList.length > 0) { this.fc_fetchMessages(response.messagesList) }
      if (callback != null) { callback(response); }
    },
    fc_fetchError(response, callback, error) {
      if (response == null) {
        if (error != null) { console.log(error); }
        this.$toast.add({ severity: 'error', summary: this.fc_getText(null, 'erroGenerico_title', { title: true }), detail: this.fc_getText(null, 'erroGenerico_msg', { first: true }), life: 4000 });
      } else {
        if (response.exception) {
          this.$toast.add({ severity: 'error', summary: this.fc_getText(null, 'erroGenerico_title', { title: true }), detail: this.fc_getText(null, 'erroGenerico_msg', { first: true }), life: 4000 });
        } else {
          if (response.errors && response.errorsList && response.errorsList.length > 0) { this.fc_fetchErrors(response.errorsList) }
          else if (response.errors) { this.fc_fetchError(null, callback, response.errors); }
          else { this.fc_fetchError(null, callback, 'unhandled exception'); }
          if (response.messages && response.messagesList && response.messagesList.length > 0) { this.fc_fetchMessages(response.messagesList) }
        }
      }
      if (callback != null) { callback(response); }
    },
    fc_fetchMessages(messagesList) {
      messagesList.forEach((item, index) => {
        this.$toast.add({ severity: item.type, summary: item.title, detail: item.msg, life: 4000 });
      });
    },
    fc_fetchErrors(errorsList) {
      errorsList.forEach((item, index) => {
        this.$toast.add({ severity: 'error', summary: item.title, detail: item.msg, life: 4000 });
      });
    },
    fc_responseList(response, list) {
      if (response != null && response.list != null) {
        list.splice(0, list.length);
        list.push.apply(list, response.list);
      }
    },
    fc_responseItem(response, item) {
      if (response != null && response.item != null) {
        Object.keys(item).forEach(key => {
          item[key] = response.item[key];
        });
      }
    },
    fc_printErrors(error) {
      if (error != null && error.stack != null) { console.error('Error', error.stack); }
      else if (error != null && error.message != null) { console.error('Error', error.message); }
      else { console.error('Error', ''); }
    },
    fc_removeHTML(string) {
      let stringFinal = string;
      stringFinal = stringFinal.replace(/<\/li>/ig, '\n');
      stringFinal = stringFinal.replace(/<li>/ig, '  *  ');
      stringFinal = stringFinal.replace(/<\/p>/ig, '\n');
      stringFinal = stringFinal.replace(/<br\s*\/?>/gi, "\n");
      stringFinal = stringFinal.replace(/&nbsp;/g, ' ');
      stringFinal = stringFinal.replace(/<[^>]+>/ig, '');
      return stringFinal;
    },
    fc_isMobile() {
      let mobile = false;
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];
      mobile = toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });

      if (!mobile) {
        const toMatchMac = [
          /Macintosh/i,
        ];
        let mackintosh = toMatchMac.some((toMatchMac) => {
          return navigator.userAgent.match(toMatchMac)
        });
        if (mackintosh && navigator.maxTouchPoints > 2) {
          mobile = true;
        }
      }
      return mobile;
    },
  }
}
