<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div class="app-page-margin-withoutImage app-page-padding">
      <AppCitacao class='paddingPagConteudo' :titulo="item.titulo" :conteudo="item.subtitulo" />
      <div class="paddingPagConteudo flex flex-wrap mt-6 gap-4 dropdownsEquipa">
        <div class="filterBox flex flex-column gap-2">
          <!-- <div class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'categoria', { title: true }) + ':'"></div> -->
          <div>
            <Dropdown @change="mt_loadData()" :options="categorias" v-model="filter.categoria" optionValue="id" optionLabel="descricao" showClear>
              <template #value="slotProps">
                <span v-if="(slotProps.value != null)" v-html="fc_getListOption(categorias, slotProps.value).descricao"></span>
                <span v-else v-html="fc_getText(null, 'filtro_categoria')"></span>
              </template>
              <template #option="slotProps">
                <div class="flex align-items-center gap-2">
                  <div v-html="slotProps.option.descricao"></div>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>
        <div class="filterBox flex flex-column gap-2">
          <!-- <div class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'areaDeAtividade', { title: true }) + ':'"></div> -->
          <div>
            <Dropdown @change="mt_loadData()" :options="areas" v-model="filter.area" optionValue="id" optionLabel="nome" showClear>
              <template #value="slotProps">
                <span v-if="(slotProps.value != null)" v-html="fc_getListOption(areas, slotProps.value).descritivoMenu"></span>
                <span v-else v-html="fc_getText(null, 'filtro_area')"></span>
              </template>
              <template #option="slotProps">
                <div class="flex align-items-center gap-2">
                  <div v-html="slotProps.option.descritivoMenu"></div>
                </div>
              </template>
            </Dropdown>
            </div>
        </div>
        <div class="filterBox flex flex-column gap-2">
          <!-- <div class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'localizacao', { title: true }) + ':'"></div> -->
          <div>
            <Dropdown @change="mt_loadData()" :options="redes" v-model="filter.rede" optionValue="id" optionLabel="descritivoMenu" showClear>
              <template #value="slotProps">
                <span v-if="(slotProps.value != null)" v-html="fc_getListOption(redes, slotProps.value).descritivoMenu"></span>
                <span v-else v-html="fc_getText(null, 'filtro_localizacao')"></span>
              </template>
              <template #option="slotProps">
                <div class="flex align-items-center gap-2">
                  <div v-html="slotProps.option.descritivoMenu"></div>
                </div>
              </template>
            </Dropdown>
            </div>
        </div>
      </div>
      <div v-if="(!list.loaded)"><AppSpinner /></div>
      <div v-if="(list.loaded && list.list.length > 0)" class="paddingPagConteudo flex flex-wrap w-full gap-3 mt-4">
        <ListItemMembro v-for="(forItem, forIndex) in list.list" :key="forIndex" :item="forItem" />
      </div>
      <div v-else class="flex w-full mt-8">
        <div class="w-full text-center" v-html="fc_getText(null, 'zeroMembros')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ListItemMembro from '@/components/ListItemMembro.vue';
export default {
  components: {
    ListItemMembro,
  },
  data() {
    return {
      loaded: false,
      item: {},
      list: {
        loaded: false,
        list: [],
      },
      filter: { categoria: null, area: null, rede: null },
      categorias: [],
      areas: [],
      redes: [],
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.loaded = true;
      this.mt_queryGet();
      this.mt_loadData();
    },
    mt_loadData() {
      this.list.loaded = false;
      this.mt_querySet();

      let query = '';
      Object.keys(this.filter).forEach(key => {
        if (this.filter[key] != null) {
          query += (query.length == 0 ? '?' : '&') + (key + '=' + this.filter[key]);
        }
      });

      this.fc_fetch({ endpoint: 'Equipa/' + query, method: 'GET', success: this.mt_loadData_success, error: () => { this.fc_goRoute('home'); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.list != null) {
        this.list.list = response.list;
      }
      this.list.loaded = true;
    },
    mt_queryGet() {
      let categoria = this.fc_getUrlParam('categoria');
      if (categoria != null && !isNaN(categoria) && this.categorias.filter(x => x.id == categoria)) {
        this.filter.categoria = Number(categoria);
      }

      let area = this.fc_getUrlParam('area');
      if (area != null && !isNaN(area) && this.areas.filter(x => x.id == area)) {
        this.filter.area = Number(area);
      }

      let rede = this.fc_getUrlParam('rede');
      if (rede != null && !isNaN(rede) && this.redes.filter(x => x.id == rede)) {
        this.filter.rede = Number(rede);
      }
    },
    mt_querySet() {
      let query = {};

      Object.keys(this.filter).forEach(key => {
        if (this.filter[key] != null) {
          query[key] = this.filter[key];
        }
      });

      this.$router.replace({ name: this.$route.name, query: query });
    },
  },
  created() {},
  mounted() {
    let promises = [];
    promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'EquipaPagina/', method: 'GET', success: (response) => { this.item = response.item; resolve(); }, error: reject }); }));
    promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'EquipaCategorias/', method: 'GET', success: (response) => { this.fc_responseList(response, this.categorias); resolve(); }, error: reject }); }));
    promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'EquipaAreas/', method: 'GET', success: (response) => { this.fc_responseList(response, this.areas); resolve(); }, error: reject }); }));
    promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'EquipaRedes/', method: 'GET', success: (response) => { this.fc_responseList(response, this.redes); resolve(); }, error: reject }); }));
    Promise.all(promises).then(() => { this.mt_start(); }).catch((e) => { this.fc_printErrors(e); });
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dropdownsEquipa .p-dropdown {
  border-radius: 0px !important;
  border-width: 0px 0px 1px 0px !important;
  border-color:#ced4da !important;
}
.dropdownsEquipa .p-dropdown:not(.p-disabled).p-focus {
  border-width: 0px 0px 1px 0px !important;
  border-color: #ced4da !important;
  box-shadow: none !important;
}
.dropdownsEquipa .p-dropdown.p-inputwrapper-filled {
  border-radius: 0px !important;
  border-width: 0px 0px 1px 0px !important;
  border-color:#000 !important;
}
.dropdownsEquipa .p-dropdown.p-inputwrapper-filled:not(.p-disabled).p-focus {
  border-width: 0px 0px 1px 0px !important;
  border-color: #000 !important;
  box-shadow: none !important;
}
.dropdownsEquipa .p-dropdown.p-inputwrapper-filled span {
  color: #000 !important;
  font-weight: 600;
}
</style>
