<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <iframe src="https://gmail.us5.list-manage.com/subscribe?u=b6a184ae2b67ba6b43a91f745&id=82dcd681c5" style="width:100%;height:75vh;" frameborder="0" allowfullscreen scrolling="yes"></iframe>
  </div>
</template>

<script>
export default {
  inject: ['dialogRef'],
  components: {},
  data() {
    return {
      loaded: false,
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.loaded = true;
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>