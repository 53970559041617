<template>
  <div class="flex w-full gap-4 border-top-2 mt-6 pt-4">
    <div class="flex align-items-end gap-4">
      <div @click="mt_linkedin()" class="rsa-button-icon"><i class="pi pi-linkedin" style="font-size: 26px"></i></div>
      <!-- <div @click="mt_instagram()" class="rsa-button-icon"><i class="pi pi-instagram" style="font-size: 26px"></i></div> -->
      <div @click="mt_facebook()" class="rsa-button-icon"><i class="pi pi-facebook" style="font-size: 26px"></i></div>
      <div @click="mt_whatsapp()" class="block lg:hidden rsa-button-icon"><i class="pi pi-whatsapp" style="font-size: 26px"></i></div>
      <div @click="mt_email()" class="rsa-button-icon"><i class="pi pi-envelope" style="font-size: 26px"></i></div>
      <div @click="mt_share" class="rsa-button-icon"><i class="pi pi-share-alt" style="font-size: 26px"></i>
        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
      </div>
    </div>
    <div class="flex align-items-center gap-4 border-left-1 ml-1 pl-4">
      <div v-html="fc_getText(null, 'partilhe', { title: true })"></div>
    </div>
  </div>
</template>

<script>
import Menu from 'primevue/menu';
import Message from 'primevue/message';
export default {
  components: {
    Menu,
    Message
  },
  data() {
    return {
      life: 0,
      items: [
          {
              label: 'Partilhar link' ,
              items: [
                  {
                      label: window.location.href,
                      icon: 'pi pi-link',
                      disabled: true
                  },
                  {
                      label: 'Copiar Link',
                      icon: 'pi pi-copy',
                      command: () => { this.mt_copyLink(); },
                  },
              ]
          }
      ]
    };
  },
  props: {
    titulo: String,
    subtitulo: String,
    imagem: String,
  },
  computed: {},
  watch: {},
  methods: {
    mt_linkedin() {
      let link = window.location.href;
      const linkedInShareUrl = "http://www.linkedin.com/shareArticle?mini=true";
      const contentQuery = '&url=' + encodeURIComponent(link);
      const shareUrl =  linkedInShareUrl + contentQuery;
      let leftPosition = (window.screen.width / 2) - ((750 / 2) + 10);
      let topPosition = (window.screen.height / 2) - ((520 / 2) + 50);
      window.open(shareUrl, '', 'left='+leftPosition+',top='+topPosition+',width=750,height=520,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
    },
    mt_instagram() {
      // to implement
    },
    mt_facebook() {
      let link = window.location.href;
      const facebookShareUrl = "https://www.facebook.com/sharer/sharer.php";
      const contentQuery = '?u=' + encodeURIComponent(link);
      const shareUrl =  facebookShareUrl + contentQuery;
      let leftPosition = (window.screen.width / 2) - ((750 / 2) + 10);
      let topPosition = (window.screen.height / 2) - ((520 / 2) + 50);
      window.open(shareUrl, '', 'left='+leftPosition+',top='+topPosition+',width=750,height=520,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
    },
    mt_whatsapp() {
      let link = window.location.href;
      const whatsappShareUrl = "whatsapp://send";
      const contentQuery = '?text=' + encodeURIComponent(link);
      const shareUrl =  whatsappShareUrl + contentQuery;
      window.open(shareUrl, '_blank');
    },
    mt_email() {
      let link = window.location.href;
      const emailShareUrl = "mailto:";
      const contentQuery = '?body=' + encodeURIComponent(link);
      const shareUrl =  emailShareUrl + contentQuery;
      window.open(shareUrl, '');
    },
    mt_share(event) {
      this.$refs.menu.toggle(event);
    },
    mt_copyLink() {
      let link = window.location.href;
      navigator.clipboard.writeText(link);
      this.$toast.add({ severity: 'success', summary: this.fc_getText(null, 'linkCopied_title', { title: true }), detail: this.fc_getText(null, 'linkCopied_msg', { first: true }), life: 4000 });
    }
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped></style>
