<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div class="grid">
      <div class="col-12 field required">
        <label for="nome" v-html="fc_getText(null, 'form_nome')"></label>
        <InputText id="nome" v-model="form.nome" :class="'w-full' + (v$.form.nome.$errors.length ? ' p-invalid' : '')" />
        <small v-if="(v$.form.nome.$errors[0])" class="rsa-color" v-html="v$.form.nome.$errors[0].$message"></small>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 field required">
        <label for="email" v-html="fc_getText(null, 'form_email')"></label>
        <InputText id="email" v-model="form.email" :class="'w-full' + (v$.form.email.$errors.length ? ' p-invalid' : '')" />
        <small v-if="(v$.form.email.$errors[0])" class="rsa-color" v-html="v$.form.email.$errors[0].$message"></small>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 field required">
        <label for="telemovel" v-html="fc_getText(null, 'form_telemovel')"></label>
        <InputText id="telemovel" v-model="form.telemovel" :class="'w-full' + (v$.form.telemovel.$errors.length ? ' p-invalid' : '')" />
        <small v-if="(v$.form.telemovel.$errors[0])" class="rsa-color" v-html="v$.form.telemovel.$errors[0].$message"></small>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 md:col-3 md:col-offset-6 xl:col-2 xl:col-offset-8">
        <Button @click="mt_formLimpar()" :label="fc_getText(null, 'form_limpar')" class="w-full" severity="secondary" />
      </div>
      <div class="col-12 md:col-3 xl:col-2">
        <Button @click="mt_formSeguinte()" :label="fc_getText(null, 'form_seguinte')" class="button-rsaColor w-full" />
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, email } from '@vuelidate/validators';
export default {
  setup () { return { v$: useVuelidate() }; },
  components: {},
  data() {
    return {
      loaded: false,
      lists: this.$parent.lists,
      form: {
        nome: null, email: null, telemovel: null,
      },
    };
  },
  validations () {
    return {
      form: {
        nome: {
          required: helpers.withMessage(this.fc_getText(null, 'field_required', { first: true }), required),
        },
        email: {
          required: helpers.withMessage(this.fc_getText(null, 'field_required', { first: true }), required),
          email: helpers.withMessage(this.fc_getText(null, 'field_invalidM', { first: true }, 'email', { title: true }), email),
        },
        telemovel: {
          required: helpers.withMessage(this.fc_getText(null, 'field_required', { first: true }), required),
          telemovel: helpers.withMessage(this.fc_getText(null, 'field_invalidM', { first: true }, 'telemovel', { title: true }), this.fc_validateTelemovel),
        },
      },
    }
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.form.nome = this.$parent.form.nome;
      this.form.email = this.$parent.form.email;
      this.form.telemovel = this.$parent.form.telemovel;
      this.loaded = true;
    },
    mt_formLimpar() {
      Object.keys(this.$parent.form).forEach(key => {
        this.$parent.form[key] = null;
      });
      Object.keys(this.form).forEach(key => {
        this.form[key] = null;
      });
    },
    async mt_formSeguinte() {
      const form_valid = await this.v$.$validate();
      if (form_valid) {
        this.loaded = false;
        this.$parent.form.nome = this.form.nome;
        this.$parent.form.email = this.form.email;
        this.$parent.form.telemovel = this.form.telemovel;
        this.$parent.fase = 2;
      }
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
