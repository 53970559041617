<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <object :data="pdf" type="application/pdf" style="width:100%;height:75vh;"></object>
  </div>
</template>

<script>
export default {
  inject: ['dialogRef'],
  components: {},
  data() {
    return {
      loaded: false,
      pdf: this.dialogRef.data.pdf,
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.loaded = true;
      this.mt_isMobile();
    },
    mt_isMobile() {
      if (!this.fc_isMobile()) {
        this.loaded = true;
      } else {
        window.open(this.pdf, '_blank', 'noreferrer');
        this.dialogRef.close();
      }
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
