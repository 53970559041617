<template>
  <div class="flex flex-column w-full mb-4">
    <div class="titulo flex flex-column rsa-font-zilla-bold text-xl md:text-2xl lg:text-4xl xl:text-6xl" v-html="title"></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    titulo: String,
    color: String,
  },
  computed: {
    title() {
      if (this.titulo.startsWith('<div><span>')) {
        return this.titulo;
      } else {
        return '<div><span>' + this.titulo + '</div></span>';
      }
    },
    titleColor() {
      if (this.color == null) {
        return 'var(--text-color)';
      } else {
        return this.color;
      }
    },
  },
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.titulo {
  color: v-bind('titleColor');
}
.titulo:deep(div:last-child > span) {
  border-bottom: 1px solid var(--text-color);
}
</style>
