<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div class="app-page-margin-withImage">
      <div class="flex flex-column-reverse lg:flex-row w-full">
        <div class="hidden lg:flex flex-column flex-grow-0">
          <div class="coluna">
            <div class="mb-4">
              <AppTitleColuna :titulo="fc_getText(null, 'rsol', { upper: true })" />
            </div>
            <div class="p-4 lg:p-0 lg:pl-8">
              <div class="rsa-font-montserrat-semibold rsa-color" v-html="fc_formatDate(item.dataPublicacao)"></div>
              <div v-if="(item.autores != null && item.autores.length > 0)" class="flex flex-column gap-1 mt-4">
                <div class="text-sm" v-html="fc_getText(null, 'por', { title: true })"></div>
                <div v-for="(forItem, forIndex) in item.autores" :key="forIndex" class="rsa-font-montserrat-semibold">
                  <div v-html="forItem"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-column flex-grow-1">
          <div class="flex image-container">
            <div class="flex-column">
              <img :src="item.imagem" />
            </div>
            <div class="flex-column hidden flex-grow-1 dots-background"></div>
          </div>
          <div class="flex flex-column gap-4 mt-4">
            <div class="pl-4 pr-6 lg:pl-0 lg:pr-4">
              <div class="rsa-font-montserrat-bold text-4xl" v-html="item.titulo"></div>
              <div class="flex lg:hidden flex-column mt-2">
                <div class="rsa-font-montserrat-semibold rsa-color" v-html="fc_formatDate(item.dataPublicacao)"></div>
                <div v-if="(item.autores != null && item.autores.length > 0)" class="flex flex-column gap-1 mt-2">
                  <div class="text-sm" v-html="fc_getText(null, 'por', { title: true })"></div>
                  <div v-for="(forItem, forIndex) in item.autores" :key="forIndex" class="rsa-font-montserrat-semibold">
                    <div v-html="forItem"></div>
                  </div>
                </div>
              </div>
              <div class="conteudoIndentacao" v-html="item.conteudo"></div>
              <div class="flex mt-4" v-if="(item.anexo != null)">
                <div @click="fc_dialogPdf(item.anexo, item.anexoNome.toUpperCase())" class="flex rsa-button-blue" v-html="fc_getText(null, 'pdfOpen')"></div>
              </div>
              <MenuShare :titulo="fc_removeHTML(item.tituloDestaque)" :subtitulo="fc_removeHTML(item.subtituloDestaque)" :imagem="item.imagem" />
              <MenuPrevNext :prevNext="prevNext" :mt_goPrev="mt_goPrev" :mt_goNext="mt_goNext" :mt_goBack="mt_goBack" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loaded: false,
      id: null,
      item: {},
      prevNext: {},
    };
  },
  props: {},
  computed: {},
  watch: {
    '$route.params'(val) {
      if (this.$route.name == 'rsolnoticia') {
        this.id = null;
        this.item = {};
        this.prevNext = {};
        this.mt_start();
      }
    },
  },
  methods: {
    mt_start() {
      this.id = this.$route.params.id;
      if (this.id == null) { this.mt_goBack(); }
      else { this.mt_loadData(); }
    },
    mt_loadData() {
      this.loaded = false;
      this.fc_fetch({ endpoint: 'RsolNoticias/' + this.id + '/', method: 'GET', success: this.mt_loadData_success, error: () => { this.mt_goBack(); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.item != null) {
        this.item = response.item;
        this.mt_prevNext();
        this.loaded = true;
      } else {
        this.mt_goBack();
      }
    },
    mt_prevNext() {
      this.fc_fetch({ endpoint: 'RsolNoticias/' + this.id + '/prevNext/', method: 'GET', success: this.mt_prevNext_success });
    },
    mt_prevNext_success(response) {
      if (response != null && response.item != null) {
        this.prevNext = response.item;
      }
    },
    mt_goPrev() {
      if (this.prevNext.prev != null) {
        this.fc_goRoute('rsolnoticia', { id: this.prevNext.prev });
      }
    },
    mt_goNext() {
      if (this.prevNext.next != null) {
        this.fc_goRoute('rsolnoticia', { id: this.prevNext.next });
      }
    },
    mt_goBack() {
      if (this.$store.state.prevRoute == null || (this.$store.state.prevRoute.name != 'rsol')) {
        this.fc_goRoute('rsol');
      } else {
        this.fc_goBack();
      }
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.coluna {
  width: 308px;
  padding-top: 2vh;
  padding-right: 30px;
}
@media screen and (min-width: 992px) {
  .coluna {
    padding-top: 56vh;
  }
  .conteudoIndentacao {
    padding-right: 5ch;
  }
}

.image-container {
  height: 60vh;
}
.dots-background {
  /* background-image: url('@/assets/design/dots.png'); */
}
.image-container img {
  max-width: 100%;
  max-height: 100%;
}
@media screen and (max-width: 992px) {
  .image-container {
    height: 100%;
  }
  .image-container img {
    width: 100%;
    height: auto;
    max-width: none;
    max-height: none
  }
}
</style>
