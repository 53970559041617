<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div :class="(item.imagem != null ? 'app-page-margin-withImage' : 'app-page-margin-withoutImage app-page-padding')">
      <AppImagem v-if="(item.imagem != null)" :imagem="item.imagem" :titulo="item.tituloImagem" :subtitulo="item.subtituloImagem" />
      <div :class="(item.imagem != null ? 'app-page-padding mt-8' : '')">
        <div class="paddingPagConteudo flex flex-column w-full">
          <AppTitleY v-if="(codigo != 'nextlaw')" :titulo="item.titulo" />
          <div v-if="(codigo != 'nextlaw')" v-html="item.conteudo"></div>
          <AppTexto v-if="(codigo == 'nextlaw')" :titulo="item.titulo" :conteudo="item.conteudo" :tituloFirstDiv="item.titulo" class="mt-6" />
          <div class="flex mt-8" v-if="(item.anexo != null)">
            <div @click="fc_dialogPdf(item.anexo, item.anexoNome.toUpperCase())" class="flex rsa-button-blue" v-html="fc_getText(null, 'pdfOpen')"></div>
          </div>
          <div v-if="(codigo == 'nextlaw')" class="text-center">
            <a href="https://www.nextlawnetwork.com/" target="_blank" class="nextlaw"><img :src="require('@/assets/design/nextlaw_original.png')" class="mt-4" style="width: 50%; height: auto;" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loaded: false,
      codigo: null,
      item: {},
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.codigo = this.$route.name;
      if (this.codigo == null) { this.mt_goBack(); }
      else { this.mt_loadData(); }
    },
    mt_loadData() {
      this.loaded = false;
      this.fc_fetch({ endpoint: 'ConteudosGerais/' + this.codigo + '/', method: 'GET', success: this.mt_loadData_success, error: () => { this.mt_goBack(); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.item != null) {
        this.item = response.item;
        this.loaded = true;
      } else {
        this.mt_goBack();
      }
    },
    mt_goBack() {
      this.fc_goRoute('home');
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nextlaw img {
  transition: all 0.3s ease 0s;
}

.nextlaw:hover img {
  transform: translateX(6%);
  transition: all 0.3s ease 0s;
}
</style>
