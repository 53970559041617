<template>
  <div class="caixaContacto flex flex-column w-full md:w-3 gap-2">
    <div>
      <div class="text-xl rsa-font-montserrat-bold" v-html="item.regiao.toUpperCase()"></div>
      <div v-if="(item.parceria != null && item.parceria != '')" class="text-sm rsa-font-montserrat-bold" v-html="fc_getText(null, 'emParceriaCom') + ' ' + item.parceria"></div>
    </div>
    <div v-if="(item.moradaArteria != null && item.moradaArteria != '')" @click="mt_openGoogleMaps()" :class="((item.linkGoogleMaps != null && item.linkGoogleMaps != '') ? ' linkGoogleMaps ' : '')">
      <div v-html="item.moradaArteria"></div>
      <div v-if="((item.moradaCp != null && item.moradaCp != '') || (item.moradaCpAlf != null && item.moradaCpAlf != ''))">
        <span v-if="(item.moradaCp != null && item.moradaCp != '')" v-html="item.moradaCp"></span>
        <span v-if="((item.moradaCp != null && item.moradaCp != '') && (item.moradaCpAlf != null && item.moradaCpAlf != ''))">&nbsp;</span>
        <span v-if="(item.moradaCpAlf != null && item.moradaCpAlf != '')" v-html="item.moradaCpAlf"></span>
      </div>
    </div>
    <div v-if="(mostrarContactosTel)" class="limiteTexto">
      <div v-if="(item.telefone != null && item.telefone != '')">
        <span class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosTelefone') + ' '"></span>
        <span v-html="item.telefone"></span>
        <span v-if="(item.chamadaRedeFixa != null && item.chamadaRedeFixa != false)"> *</span>
      </div>
      <div v-if="(item.telemovel != null && item.telemovel != '')">
        <span class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosTelemovel') + ' '"></span>
        <span v-html="item.telemovel"></span>
      </div>
      <div v-if="(item.telemovelAlternativo != null && item.telemovelAlternativo != '')">
        <span class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosTelemovel') + ' '"></span>
        <span v-html="item.telemovelAlternativo"></span>
      </div>
      <div v-if="(item.fax != null && item.fax != '')">
        <span class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosFax') + ' '"></span>
        <span v-html="item.fax"></span>
      </div>
    </div>
    <div v-if="(item.email != null && item.email != '')" class="limiteTexto">
      <span class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosEmail') + ' '"></span>
      <a :href="'mailto:' + item.email" target="_blank" v-html="item.email"></a>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    item: Object,
  },
  computed: {
    mostrarContactosTel() {
      if (
        (this.item.telefone != null && this.item.telefone != '') ||
        (this.item.telemovel != null && this.item.telemovel != '') ||
        (this.item.fax != null && this.item.fax != '')
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
  methods: {
    mt_openGoogleMaps(){
      if (this.item.linkGoogleMaps != null && this.item.linkGoogleMaps != '') {
        window.open(this.item.linkGoogleMaps, '_blank', 'noreferrer');
      }
    }
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.caixaContacto {
  border-left: 1px solid black;
  padding-left: 2vw;
  margin-right: 3vw;
}
.linkGoogleMaps:hover {
  cursor:pointer;
  color: var(--rsa-color);
}
.limiteTexto {
  word-wrap: break-word;
}
</style>
