<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div :class="(item.imagem != null ? 'app-page-margin-withImage' : 'app-page-margin-withoutImage app-page-padding')">
      <AppImagem v-if="(item.imagem != null)" :imagem="item.imagem" :titulo="item.tituloImagem" :subtitulo="item.subtituloImagem" :position="'top right'" />
      <div :class="(item.imagem != null ? 'app-page-padding mt-8' : '')">
        <div v-if="(item.conteudos != null)" class="paddingPagConteudo">
          <div v-for="(forItem, forIndex) in item.conteudos" :key="forIndex" :class="(forIndex > 0 ? 'mt-8' : '')">
            <AppTexto :titulo="forItem.titulo" :conteudo="forItem.descConteudo" :tituloFirstDiv="item.conteudos[0].titulo" class="mt-6" />
          </div>
        </div>
        <div class="mt-8 paddingPagConteudo">
          <AppTitleY :titulo="fc_getText(null, 'venhaFazerParte')" />
          <FormRecrutamento />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormRecrutamento from '@/components/FormRecrutamento.vue';
export default {
  components: {
    FormRecrutamento,
  },
  data() {
    return {
      loaded: false,
      item: {},
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.mt_loadData();
    },
    mt_loadData() {
      this.loaded = false;
      this.fc_fetch({ endpoint: 'RecrutamentoPagina/', method: 'GET', success: this.mt_loadData_success, error: () => { this.mt_goBack(); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.item != null) {
        this.item = response.item;
        this.loaded = true;
      } else {
        this.mt_goBack();
      }
    },
    mt_goBack() {
      this.fc_goRoute('home');
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
