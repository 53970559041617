<template>
  <div class="spinner text-center">
    <ProgressSpinner
      style="width:50px;height:50px"
      strokeWidth="4"
      :fill="(fill == null ? 'var(--rsa-gray-01)' : fill)"
      animationDuration=".5s"
      :pt="{
        circle: {
          style:
          {
            stroke: (stroke == null ? 'var(--rsa-color)' : stroke),
            animation: 'p-progress-spinner-dash 1.5s ease-in-out infinite',
          },
        },
      }"
    />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    fill: String,
    stroke: String,
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.spinner {
  padding-top: 160px;
}
@media screen and (min-width: 992px) {
  .spinner {
    padding-top: 80px;
  }
}
</style>
