<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div class="flex flex-column w-full justify-content-center gap-2">
      <div class="flex w-full justify-content-center">
        <span v-html="fc_getText(null, 'newsletterMsg', { first: true })"></span>
      </div>
      <div class="flex w-full justify-content-center">
        <Button @click="mt_newsletter()" :label="fc_getText(null, 'newsletterSubscreva', { first: true })" class="button-rsaColor" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['dialogRef'],
  components: {},
  data() {
    return {
      loaded: false,
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.loaded = true;
    },
    mt_newsletter() {
      this.fc_dialogNewsletterForm();
      this.dialogRef.close();
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
