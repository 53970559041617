<template>
  <div class="SocialMediaPhone flex fixed top-25 right-0 z-6">
    <div class="flex flex-column">
      <div v-if="(linksSocialMedia.linkLinkedin != null && linksSocialMedia.linkFacebook != '')" @click="mt_linkedin()" class="media-button flex justify-content-center align-items-center cursor-pointer border-round-left-md">
        <i class="pi pi-linkedin buttonSize"></i>
      </div>
      <div v-if="(linksSocialMedia.linkYoutube != null && linksSocialMedia.linkFacebook != '')" @click="mt_youtube()" class="media-button flex justify-content-center align-items-center cursor-pointer border-round-left-md">
        <i class="pi pi-youtube buttonSize"></i>
      </div>
      <div v-if="(linksSocialMedia.linkInstagram != null && linksSocialMedia.linkFacebook != '')" @click="mt_instagram()" class="media-button flex justify-content-center align-items-center cursor-pointer border-round-left-md">
        <i class="pi pi-instagram buttonSize"></i>
      </div>
      <div v-if="(linksSocialMedia.linkFacebook != null && linksSocialMedia.linkFacebook != '')" @click="mt_facebook()" class="media-button flex justify-content-center align-items-center cursor-pointer border-round-left-md">
        <i class="pi pi-facebook buttonSize"></i>
      </div>
      <!-- <div @click="mt_whatsapp()" class="media-button next-to-last justify-content-center align-items-center cursor-pointer flex border-round-left-md">
        <i class="pi pi-whatsapp" style="font-size: 22px"></i>
      </div> -->
      <div @click="mt_contactos()" class="media-button last justify-content-center align-items-center cursor-pointer flex border-round-left-md">
        <i class="pi pi-envelope buttonSize"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    linksSocialMedia: Object,
  },
  computed: {},
  watch: {},
  methods: {
    mt_linkedin() {
      window.open(this.linksSocialMedia.linkLinkedin, '_blank', 'noreferrer');
    },
    mt_youtube() {
      window.open(this.linksSocialMedia.linkYoutube, '_blank', 'noreferrer');
    },
    mt_instagram() {
      window.open(this.linksSocialMedia.linkInstagram, '_blank', 'noreferrer');
    },
    mt_facebook() {
      window.open(this.linksSocialMedia.linkFacebook, '_blank', 'noreferrer');
    },
    mt_whatsapp() {
      // to implement
    },
    mt_contactos() {
      this.fc_goRoute('contactos');
    },
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.media-button {
  width: 40px;
  height: 40px;
  margin-bottom: 2px;
  color: var(--rsa-color);
  background-color: var(--rsa-gray-01);
}
.buttonSize {
    font-size: 22px;
}
@media screen and (max-height: 600px) {
  .media-button {
    width:30px;
    height:30px;
  }
  .buttonSize {
      font-size: 16px;
  }
}
.media-button:hover {
  color: var(--rsa-color);
  background-color: var(--rsa-color-02);
}
.media-button.next-to-last {
  margin-top: 10px;
  color: #fff;
  background-color: var(--rsa-color);
}
.media-button.last {
  color: #fff;
  background-color: var(--rsa-color);
}
 .media-button.next-to-last:hover {
   margin-top: 10px;
 }
.media-button.last:hover, .media-button.next-to-last:hover {
  color: var(--rsa-color);
  background-color: var(--rsa-color-02);
}
@media screen and (max-width: 992px) and (max-height: 600px) {
  .SocialMediaPhone {
    top: 45%;
  }
}
@media screen and (max-width: 992px) and (min-height: 600px) {
  .SocialMediaPhone {
    top: 35%;
  }
}
</style>
