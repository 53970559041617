<template>
  <div @click="mt_go()" class="item flex flex-column">
    <div class="imagem-caixa">
      <div :class=" ((item.ajustarImagem == 'False') ? 'imagem ' : 'imagemAjustada ') + ((item.delimitarImagem == 'True') ? 'imagem-border' : '')" :style="'background-image: url(' + (item.imagemPersonalizada == null ? item.imagem : item.imagemPersonalizada ) + ');'"></div>
    </div>
    <div class="pt-2">
      <div class="text-sm rsa-font-montserrat-bold mt-2 rsa-color" v-html="tipoTitle + fc_formatDate(item.dataPublicacao)"></div>
      <div class="rsa-font-montserrat-bold mt-2" v-html="item.titulo"></div>
      <div class="text-xs" v-html="item.subtitulo"></div>
    </div>
    <div class="saberMais mt-6 rsa-gray-03">
      <i class="pi pi-plus text-xs font-bold mr-2"></i>
      <span class="text-xs" v-html="fc_getText(null, 'saberMais', { upper: true })"></span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    item: Object,
    tipo: Number,
  },
  computed: {
    tipoTitle() {
      if (this.tipo == 1) {
        return this.fc_getText(null, 'eventos', { upper: true }) + ' - ';
      } else if (this.tipo == 2) {
        return this.fc_getText(null, 'noticiasImprensa', { upper: true }) + ' - ';
      } else if (this.tipo == 3) {
        return this.fc_getText(null, 'videos', { upper: true }) + ' - ';
      } else if (this.tipo == 4) {
        return this.fc_getText(null, 'publicacoes', { upper: true }) + ' - ';
      } else {
        return '';
      }
    },
  },
  watch: {},
  methods: {
    mt_go() {
      if (this.tipo == 1) {
        this.fc_goRoute('evento', { id: this.item.id });
      } else if (this.tipo == 2) {
        this.fc_goRoute('noticia', { id: this.item.id });
      } else if (this.tipo == 3) {
        this.fc_goRoute('video', { id: this.item.id });
      } else if (this.tipo == 4) {
        if (this.item.visualizacao == 2) {
          window.open(this.item.linkPublicacaoExterna, '_blank', 'noreferrer');
        } else if (this.item.visualizacao == 3) {
          this.fc_dialogPdf(this.item.anexo, this.item.anexoNome.toUpperCase());
        } else if (this.item.visualizacao == 4) {
          this.fc_dialogPlayer(this.item.titulo, this.item.linkPlayer);
        } else {
          this.fc_goRoute('publicacao', { id: this.item.id });
        }
      }
    },
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.item {
  cursor: pointer;
}
.item:hover .saberMais {
  color: var(--rsa-color);
  font-family: var(--rsa-font-montserrat-bold);
}
/* .imagem-caixa {
  width: 100%;
  height: 300px;
  background-color: black;
}
.imagem-caixa .imagem {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
} */
.imagem-caixa {
  position: relative;
  width: 100%;
  padding-bottom: 52%;
  height: 0;
  background-color: black;
}
.imagem-caixa .imagem {
  width: 100%;
  height: 100%;
  position:absolute;
  left:0;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;
}
.imagem-caixa .imagemAjustada {
  width: 100%;
  height: 100%;
  position:absolute;
  left:0;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto 100%;
}
.imagem-border {
  border: 1px solid black;
}
</style>
