<template>
  <div @click="fc_goRoute('evento', { id: item.id })" class="item-button flex w-full border-bottom-1 py-2 md:py-4 lg:py-5 xl:py-6">
    <div class="flex flex-column flex-grow-0 mx-2 md:mx-4 dataEvento">
      <div class="item-dia rsa-font-montserrat-semibold text-xl sm:text-4xl" v-html="fc_formatDay(item.dataEvento)"></div>
      <div class="item-mes rsa-font-montserrat-light text-xl sm:text-2xl" v-html="fc_formatMonthDesc(item.dataEvento)"></div>
      <div class="item-ano rsa-font-montserrat-light" v-html="fc_formatYear(item.dataEvento)"></div>
    </div>
    <div class="flex flex-grow-1 flex-column sm:flex-row">
      <div class="hidden xl:flex flex-grow-0 pl-2 max-w-22rem">
        <div class="imagem-caixa">
          <div class="imagem" :style="'background-image: url(' + (item.imagemPersonalizada == null ? item.imagemLista : item.imagemPersonalizada)  + ');'"></div>
        </div>
      </div>
      <div class="flex flex-grow-0 mx-2 md:mx-4 border-left-1 pl-2 max-w-22rem">
        <div class="flex flex-column align-self-center">
          <div class="text-xl sm:text-2xl rsa-font-montserrat-bold" v-html="item.tituloDestaque"></div>
          <div v-html="item.subtituloDestaque"></div>
        </div>
      </div>
      <div class="flex flex-grow-1"></div>
      <div class="flex flex-grow-0 justify-content-end mx-2 md:mx-4 lg:mx-5 xl:mx-6 my-2 md:my-0">
        <div class="flex align-self-center rsa-button-gray" v-html="fc_getText(null, 'saberMais')"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    item: Object,
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.item-button {
  cursor: pointer;
}
.item-button:hover {
  background-color: var(--rsa-gray-01);
}
.item-button:hover .rsa-button-gray {
  background-color: var(--rsa-color);
}
.item-button:hover .item-dia {
  color: var(--rsa-color);
}
.item-button:hover .item-mes {
  color: var(--rsa-color);
}
.imagem-caixa {
  width: 250px;
  height: 141px;
  background-color: black;
}
.imagem-caixa .imagem {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.dataEvento {
  flex: 0 0 130px;
}
@media screen and (max-width: 576px) {
   .dataEvento {
     flex: 0 0 110px;
   }
}
</style>
