<template>
  <Dropdown @change="mt_lang()" :options="langs" v-model="$store.state.lang" optionLabel="code" optionValue="code" class="menuIdioma" panelClass="menuIdiomaPanel">
    <template #value="slotProps">
      <div v-if="slotProps.value" class="flex align-items-center">
        <div class="text-xs" v-html="langs.find(x => x.code == slotProps.value).desc"></div>
      </div>
    </template>
    <template #option="slotProps">
      <div class="flex align-items-center">
        <div class="text-xs" v-html="slotProps.option.desc"></div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from 'primevue/dropdown';
export default {
  components: {
    Dropdown,
  },
  data() {
    return {
      langs: [
        {
          name: 'Português',
          code: 'pt',
          desc: 'PT',
        },
        {
          name: 'English',
          code: 'en',
          desc: 'EN',
        },
        {
          name: 'French',
          code: 'fr',
          desc: 'FR',
        },
        {
          name: 'Spanish',
          code: 'es',
          desc: 'ES',
        },
      ],
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_lang() {
      this.fc_setLang();
      this.fc_go();
    },
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.menuIdiomaPanel.p-dropdown-panel {
  border: none;
}

.menuIdiomaPanel .p-dropdown-items-wrapper {
  background-color: var(--rsa-color);
  border-radius: 0px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.menuIdiomaPanel .p-dropdown-item {
  color: #fff;
}

.menuIdiomaPanel .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--rsa-color-02);
  background: none;
}
</style>

<style scoped>
.menuIdioma {
  background: none;
  border: none;
  margin-right: 5px;
}

.menuIdioma:deep(.p-dropdown-trigger) {
  width: auto;
  color: #fff;
  margin-right: 3px;
}

.menuIdioma:hover:deep(.p-dropdown-trigger) {
  color: var(--rsa-color-02);
}

.menuIdioma:deep(.p-inputtext) {
  color: #fff;
  padding: 0.4rem 0.2rem;
}

.menuIdioma:hover:deep(.p-inputtext) {
  color: var(--rsa-color-02);
}
</style>
