<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div :class="(item.imagem != null ? 'app-page-margin-withImage' : 'app-page-margin-withoutImage app-page-padding')">
      <AppImagem v-if="(item.imagem != null)" :imagem="item.imagem" :titulo="item.tituloImagem" :subtitulo="item.subtituloImagem" />
      <AppCitacao :titulo="item.titulo" :conteudo="item.subtitulo" :class="(item.imagem != null ? 'paddingPagConteudo-withImage mt-6' : 'paddingPagConteudo mt-6')" />
      <div :class="(item.imagem != null ? 'app-page-padding mt-6' : '')">
        <div class="paddingPagConteudo flex flex-wrap w-full">
          <div v-if="(item.link != null && item.link != '')" class="linkSobreNos mt-4">
            <iframe :src="item.link" frameborder="0" allowfullscreen scrolling="no"></iframe>
          </div>
          <div v-if="(item.anexo != null && !this.fc_isMobile())" class="anexoSobreNos mt-4">
            <object :data="item.anexo" type="application/pdf"></object>
          </div>
          <div class="flex mt-8" v-if="(item.anexo != null && this.fc_isMobile())">
            <div @click="fc_dialogPdf(item.anexo, item.anexoNome.toUpperCase())" class="flex rsa-button-blue" v-html="fc_getText(null, 'pdfBrochuraRsa')"></div>
          </div>
          <div class="flex flex-row-reverse">
            <div class="lg:w-12 xl:w-12 mt-4 " v-html="item.conteudo"></div>
          </div>
          <div v-if="(item.segundaImagem != null)">
            <img :src="item.segundaImagem" class="mt-4" style="width:80%;height:auto;" />
          </div>
          <div class="flex flex-row-reverse" v-if="(item.segundoConteudo != null && item.segundoConteudo != '')">
            <div class="lg:w-12 xl:w-12 mt-4 " v-html="item.segundoConteudo"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loaded: false,
      item: {},
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.mt_loadData();
    },
    mt_loadData() {
      this.loaded = false;
      this.fc_fetch({ endpoint: 'SobreNos/', method: 'GET', success: this.mt_loadData_success, error: () => { this.mt_goBack(); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.item != null) {
        this.item = response.item;
        this.loaded = true;
      } else {
        this.mt_goBack();
      }
    },
    mt_goBack() {
      this.fc_goRoute('home');
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 992px) {
  .paddingPagConteudo-withImage {
    padding: 0vw 8vw 4vw 6vw;
  }
}
@media screen and (min-width: 992px) {
  .paddingPagConteudo-withImage {
    padding: 0vw 8vw 4vw 315px;
  }
}
@media screen and (max-width: 1400px) {
  .linkSobreNos {
    width: 100% !important;
  }
  .anexoSobreNos {
    width: 100% !important;
  }
  .linkSobreNos iframe {
    width: 80%;
    aspect-ratio:1/0.67;
  }
  .anexoSobreNos object {
    width: 80%;
    aspect-ratio:1/0.67;
  }
}
@media screen and (max-width: 500px) {
  .linkSobreNos {
    width: 100% !important;
  }
  .anexoSobreNos {
    width: 100% !important;
  }
  .linkSobreNos iframe {
      width: 100%;
      aspect-ratio:1/0.67;
  }
  .anexoSobreNos object {
    width: 100%;
    height: 45vh;
  }
}
@media screen and (min-width: 1400px) {
  .linkSobreNos {
    width: 50% !important;
  }
  .anexoSobreNos {
    width: 50% !important;
  }
  .linkSobreNos iframe {
      width: 98%;
      aspect-ratio:1/0.67;
  }
  .anexoSobreNos object {
    margin-left: 3%;
    width: 98%;
    aspect-ratio:1/0.67;
  }
}
</style>
