<template>
  <div>
    <div class="mapa-mundo flex flex-column justify-content-center w-full">
      <!-- <div class="p-5 text-center text-shadow-black-2 text-xl lg:text-2xl xl:text-4xl rsa-font-zilla" v-html="fc_getText(null, 'bottomContent_01')"></div> -->
      <div class="countrys text-center text-shadow-black-2 text-sm" v-html="fc_getText(null, 'bottomContent_02')"></div>
      <!-- <div class="pz-5 pt-8 pb-5 text-center text-shadow-black-2 text-sm rsa-font-montserrat-semibold" v-html="fc_getText(null, 'bottomContent_03')"></div> -->
    </div>
    <div class="hidden md:flex w-full justify-content-center gap-2 lg:gap-4 xl:gap-8 px-2 lg:px-4 xl:px-8">
      <div class="logo flex flex-grow-1" :style="'background-image: url(' + require('@/assets/design/barra-logos.png') + ');'"></div>
      <!-- <div class="logo flex flex-grow-1" :style="'background-image: url(' + require('@/assets/design/logo_chambers.jpg') + ');'"></div>
      <div class="logo flex flex-grow-1" :style="'background-image: url(' + require('@/assets/design/logo_legal500.jpg') + ');'"></div>
      <div class="logo flex flex-grow-1" :style="'background-image: url(' + require('@/assets/design/logo_euromoney.jpg') + ');'"></div>
      <div class="logo flex flex-grow-1" :style="'background-image: url(' + require('@/assets/design/logo_intl.jpg') + ');'"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.mapa-mundo {
  color: #fff;
  background-image: url('@/assets/design/mapa_mundo.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.mapa-mundo > .countrys {
  background-image: url('@/assets/design/RSA_REDE.png');
  background-repeat: no-repeat;
  background-position: 50% 30%;
  background-size: 27rem;
  padding: 2rem;
  padding-top: 9.5rem;
  padding-bottom: 9.5rem;
}
.logo {
  height: 260px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
@media screen and (max-width:768px) {
  .mapa-mundo > .countrys {
    background-size: 21rem;
  }
}
</style>
