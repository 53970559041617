<template>
  <div class="banner w-full" :style="((view == 'fichaTecnica') ? 'position:relative;' : '') + ' background-image: url(' + imagem + ');'">
    <div class="flex flex-column w-full">
      <div :class="'banner-text w-full ' + (view == 'fichaTecnica' ? 'fichaTecnica' : '')">
        <div class="block sm:hidden">
          <div v-if="(view != 'fichaTecnica')" class="banner-text-titulo flex flex-column fadeinup animation-duration-1000" v-html="titulo"></div>
          <div v-if="(view == 'fichaTecnica')" class="banner-text-titulo flex flex-column fadeinup animation-duration-1000" v-html="'<div><span>' + nome + '</span></div>'"></div>
        </div>
        <div class="hidden sm:block">
          <div class="banner-text-titulo flex flex-column fadeinup animation-duration-1000" v-html="titulo"></div>
        </div>
        <div class="flex gap-0">
          <!-- <div class="banner-text-titulo hidden sm:flex flex-column flex-grow-0 opacity-0" v-html="tituloLastDiv"></div> -->
          <div v-if="(categorias == null)" class="banner-text-subtitulo flex flex-column flex-grow-1 fadeinleft animation-duration-1000" v-html="subtitulo"></div>
          <div v-if="(categorias != null)" class="banner-text-subtitulo flex flex-column flex-grow-1 fadeinleft animation-duration-1000" v-html="categoriasDiv"></div>
        </div>
        <div v-if="(contacto != null && contacto != '')" class="flex gap-0">
          <!-- <div class="banner-text-titulo hidden sm:flex flex-column flex-grow-0 opacity-0" v-html="tituloLastDiv"></div> -->
          <div class="banner-text-contacto flex flex-column flex-grow-1 fadeinleft animation-duration-1000">
            <div v-html="fc_getText(null, 'membroContacto')"></div>
            <div class="rsa-font-montserrat-bold" v-html="contactoDiv"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    imagem: String,
    titulo: String,
    subtitulo: String,
    categorias: Array,
    position: String,
    contacto: String,
    nome: String,
    view: String
  },
  computed: {
    categoriasDiv() {
      let categorias = "";
      if (this.categorias.length > 0){
        this.categorias.forEach((categoria,index) => {
          categorias += categoria;
          if (index < this.categorias.length - 1) {
            categorias += " | ";
          }
        });
        return categorias;
      } else {
        return categorias;
      }
    },
    contactoDiv(){
      if (this.contacto != "") {
        let linkContacto = "<a href='mailto:" + this.contacto + "' target='_blank'>" + this.contacto + "</a>";
        return linkContacto;
      }
      else {
        return this.contacto;
      }
    },
    backgroundPosition() {
      if (this.position == null) {
        return 'center center';
      } else {
        return this.position;
      }
    },
    tituloLastDiv() {
      const divs = this.titulo.split('<div>');
      if (divs.length > 1) {
        return '<div>' + divs[divs.length - 1] + '';
      } else {
        return this.titulo;
      }
    },
  },
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.banner {
  height: 70vh;
  background-repeat: no-repeat;
  background-position: v-bind('backgroundPosition');
  background-size: cover;
}

.banner .banner-text {
  padding-top: 18vh;
  padding-right: 8vw;
}

.banner .banner-text .banner-text-titulo {
  color: #fff;
  font-family: var(--rsa-font-zilla-semibold);
  font-size: 36px;
}
@media screen and (max-width: 576px) {
  .banner .banner-text .banner-text-titulo:deep(div > span) {
    padding-left: 6vw;
  }
  .banner .banner-text .banner-text-titulo {
    font-size: 26px;
  }
  .banner .banner-text .banner-text-subtitulo {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 576px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 36px;
  }
  .banner .banner-text.fichaTecnica .banner-text-titulo {
    font-size: 3.5vh;
  }
  .banner .banner-text .banner-text-titulo:deep(div > span) {
    padding-left: 6vw;
  }
}
@media screen and (min-width: 768px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 40px;
  }
  .banner .banner-text.fichaTecnica .banner-text-titulo {
    font-size: 3.9vh;
  }
}
@media screen and (min-width: 992px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 45px;
  }
  .banner .banner-text.fichaTecnica .banner-text-titulo {
    font-size: 4.5vh;
  }
  .banner .banner-text .banner-text-titulo:deep(div > span) {
    padding-left: 315px;
  }
}
@media screen and (min-width: 1200px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 55px;
  }
  .banner .banner-text.fichaTecnica .banner-text-titulo {
    font-size: 5.5vh;
  }
}
@media screen and (min-width: 1400px) {
  .banner .banner-text.fichaTecnica .banner-text-titulo {
    font-size: 6.5vh;
  }
}
@media screen and (max-height: 600px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 30px;
  }
  .banner .banner-text .banner-text-subtitulo {
    font-size: 3.5vh !important;
  }
}
.banner .banner-text .banner-text-titulo:deep(div) {
  margin-top: -8px;
}
.banner .banner-text .banner-text-titulo:deep(div > span) {
  white-space: nowrap;
}
.banner .banner-text .banner-text-titulo:deep(div:last-child > span) {
  border-bottom: 1px solid #fff;
}

.banner .banner-text .banner-text-subtitulo {
  color: #fff;
  padding: 10px 0px;
  font-size: 18px;
  padding-left: 6vw;
}
@media screen and (min-width: 576px) {
  .banner .banner-text .banner-text-subtitulo {
    font-size: 16px;
    padding-left: 6vw;
  }
  .banner .banner-text.fichaTecnica .banner-text-subtitulo {
    font-size: 2.2vh;
  }
}
@media screen and (min-width: 768px) {
  .banner .banner-text .banner-text-subtitulo {
    font-size: 20px;
    padding-left: 6vw;
  }
  .banner .banner-text.fichaTecnica .banner-text-subtitulo {
    font-size: 2.5vh;
  }
}
@media screen and (min-width: 992px) {
  .banner .banner-text .banner-text-subtitulo {
    font-size: 20px;
    padding-left: 315px;
  }
  .banner .banner-text.fichaTecnica .banner-text-subtitulo {
    font-size: 2.7vh;
  }
}
@media screen and (min-width: 1200px) {
  .banner .banner-text .banner-text-subtitulo {
    font-size: 24px;
    padding-left: 315px;
  }
  .banner .banner-text.fichaTecnica .banner-text-subtitulo {
    font-size: 2.9vh;
  }
}

.banner .banner-text-contacto {
  color: #fff;
  padding-left: 6vw;
}
@media screen and (min-width: 576px) {
  .banner .banner-text-contacto {
    padding-left: 6vw;
  }
}
@media screen and (min-width: 992px) {
  .banner .banner-text-contacto {
    padding-left: 315px;
  }
}
@media screen and (min-width: 1200px) {
  .banner .banner-text-contacto {
    padding-left: 315px;
  }
}
@media screen and (max-width: 575px) {
  .fichaTecnica  {
    padding-top: 2vh !important;
    padding-bottom: 2vh !important;
    background: black;
    bottom: 0 !important;
    position: absolute;
  }
  .fichaTecnica .banner-text-titulo {
    color: white !important;
    font-size: 25px !important;
  }
}
</style>
