<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div :class="(item.imagemFichaTecnica != null ? 'app-page-margin-withImage' : 'app-page-margin-withoutImage app-page-padding')">
      <AppImagem v-if="(item.imagemFichaTecnica != null)" class='color-titleMembroView' :imagem="item.imagemFichaTecnica" :titulo="item.titulo" :categorias="item.categorias" :position="'top right'" :contacto="item.email" :nome="item.nome" :view="'fichaTecnica'" />
      <div v-if="(item.experiencia != null && item.experiencia.length > 0)" class="cv rsa-color-bg text-white w-full sm:w-8 md:w-7 lg:w-6 xl:w-5 mx-0 sm:mx-5 md:mx-6 lg:mx-7 xl:mx-8 p-6">
        <AppTitleX :titulo="fc_getText(null, 'membroCv')" :color="'#fff'" />
        <div class="flex flex-column paddingMembroEspecificidades">
          <ul>
            <li v-for="(forItem, forIndex) in item.experiencia" :key="forIndex" v-html="forItem"></li>
          </ul>
        </div>
      </div>
      <div :class="(item.imagemFichaTecnica != null ? 'app-page-padding mt-8' : '')">
        <div v-if="(item.areas != null && item.areas.length > 0)" class="paddingPagConteudo mb-4">
          <AppTitleX :titulo="fc_getText(null, 'membroAreas')" />
          <div class="flex flex-column paddingMembroEspecificidades">
            <ul>
              <li v-for="(forItem, forIndex) in item.areas" :key="forIndex" v-html="forItem.descricao" @click="fc_goRoute('area', { id: forItem.id })" class="areaMembro"></li>
            </ul>
          </div>
        </div>
        <div v-if="(item.formacao != null && item.formacao.length > 0)" class="paddingPagConteudo mb-4">
          <AppTitleX :titulo="fc_getText(null, 'membroFormacao')" />
          <div class="flex flex-column paddingMembroEspecificidades">
            <ul>
              <li v-for="(forItem, forIndex) in item.formacao" :key="forIndex" v-html="forItem"></li>
            </ul>
          </div>
        </div>
        <div v-if="(item.outras != null && item.outras.length > 0)" class="paddingPagConteudo mb-4">
          <AppTitleX :titulo="fc_getText(null, 'membroOutras')" />
          <div class="flex flex-column paddingMembroEspecificidades">
            <ul>
              <li v-for="(forItem, forIndex) in item.outras" :key="forIndex" v-html="forItem"></li>
            </ul>
          </div>
        </div>
        <div v-if="(item.idiomas != null && item.idiomas.length > 0)" class="paddingPagConteudo mb-4">
          <AppTitleX :titulo="fc_getText(null, 'membroIdiomas')" />
          <div class="flex flex-column paddingMembroEspecificidades">
            <ul>
              <li v-for="(forItem, forIndex) in item.idiomas" :key="forIndex" v-html="forItem"></li>
            </ul>
          </div>
        </div>
        <div v-if="(item.premios != null && item.premios.length > 0)" class="paddingPagConteudo mb-4">
          <AppTitleX :titulo="fc_getText(null, 'membroPremios')" />
          <div class="flex flex-column paddingMembroEspecificidades">
            <ul>
              <li v-for="(forItem, forIndex) in item.premios" :key="forIndex" v-html="forItem"></li>
            </ul>
          </div>
        </div>
        <div v-if="(item.publicacoes != null && item.publicacoes.length > 0)" class="paddingPagConteudo mb-4">
          <AppTitleX :titulo="fc_getText(null, 'membroPublicacoes')" />
          <div class="flex flex-column paddingMembroEspecificidades">
            <div v-for="(forItemPublicacao, forIndexPublic) in publicacoesParticipacoesTipos" :key="forIndexPublic">
              <div v-if="(forItemPublicacao.publicacoes.length > 0)">
                <div class="rsa-font-montserrat-bold" v-html="fc_getText(null, forItemPublicacao.codigo, { title: true })"></div>
                <ul>
                  <li :class="((forItem.publicacaoExterna || forItem.apenasIntranet) ? '' : 'publicacaoLink')" @click="mt_go(forItem)" v-for="(forItem, forIndex) in forItemPublicacao.publicacoes" :key="forIndex" v-html="(forItem.publicacaoExterna ? forItem.publicacaoExternaDesc : forItem.descricao)"></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loaded: false,
      id: null,
      item: {},
      publicacoesParticipacoesTipos: []
    };
  },
  props: {},
  computed: {},
  watch: {
    '$route.params'(val) {
      if (this.$route.name == 'membro') {
        this.id = null;
        this.item = {};
        this.mt_start();
      }
    },
  },
  methods: {
    mt_start() {
      this.id = this.$route.params.id;
      if (this.id == null) { this.mt_goBack(); }
      else { this.mt_loadData(); }
    },
    mt_loadData() {
      this.loaded = false;
      let queryRede = this.$store.state.prevRoute.query.rede;
      let query = "";
      if (queryRede != null) {
        query += "?rede="+queryRede;
      }
      this.fc_fetch({ endpoint: 'EquipaMembros/' + this.id + '/' + query, method: 'GET', success: this.mt_loadData_success, error: () => { this.mt_goBack(); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.item != null) {
        this.item = response.item;
        this.publicacoesParticipacoesTipos.forEach((item,index) => {
          item.publicacoes = [];
        });
        let tipoParticipacao;
        this.item.publicacoes.forEach((item,index) => {
          let findTipo = this.publicacoesParticipacoesTipos.findIndex((o) => o.id == item.tipoParticipacao)
          if (findTipo > -1) {
            this.publicacoesParticipacoesTipos[findTipo].publicacoes.push(item)
          }
        });
        this.loaded = true;
      } else {
        this.mt_goBack();
      }
    },
    mt_go(item) {
      if (!item.publicacaoExterna && item.id != null && !item.apenasIntranet) {
        if (item.publicVisualizacao == 2) {
          window.open(item.publicLinkPublicacaoExterna, '_blank', 'noreferrer');
        } else if (item.publicVisualizacao == 3) {
          this.fc_dialogPdf(item.publicAnexo, item.publicAnexoNome.toUpperCase());
        } else if (item.publicVisualizacao == 4) {
          this.fc_dialogPlayer(item.descricao, item.publicLinkPlayer);
        } else {
          this.fc_goRoute('publicacao', { id: item.id });
        }
      }
    },
    mt_goBack() {
      if (this.$store.state.prevRoute == null || (this.$store.state.prevRoute.name != 'equipa' && this.$store.state.prevRoute.name != 'area')) {
        this.fc_goRoute('equipa');
      } else {
        this.fc_goBack();
      }
    },
  },
  created() {},
  mounted() {
    let promises = [];
    promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'PublicacoesParticipacoesTipos/', method: 'GET', success: (response) => { this.publicacoesParticipacoesTipos = response.list; resolve(); }, error: reject }); }));
    Promise.all(promises).then(() => { this.mt_start(); }).catch((e) => { this.fc_printErrors(e); });
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cv {
  position: relative;
  top: -60px;
}
@media screen and (min-width: 576px) {
  .color-titleMembroView:deep(div > span) {
    color: var(--text-color) !important;
  }
  .color-titleMembroView:deep(div > div) {
    color: var(--text-color) !important;
  }
  .color-titleMembroView:deep(div:last-child > span) {
    border-bottom: 1px solid var(--text-color) !important;
  }
}

@media screen and (max-width: 576px) {
  .cv {
    top: 0px;
  }
}
@media screen and (min-width: 992px) {
  .cv {
    margin-left: 26vw !important;
  }
}
@media screen and (min-width: 1200px) {
  .cv {
    margin-left: 23vw !important;
  }
}
@media screen and (min-width: 1400px) {
  .cv {
    margin-left: 20vw !important;
  }
}
@media screen and (min-width: 1600px) {
  .cv {
    margin-left: 17vw !important;
  }
}
@media screen and (min-width: 1700px) {
  .cv {
    margin-left: 14vw !important;
  }
}
.areaMembro:hover,
.publicacaoLink:hover {
  color: var(--rsa-color);
  cursor: pointer;
}
</style>
