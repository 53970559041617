<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div :class="(item.imagem != null ? 'app-page-margin-withImage' : 'app-page-margin-withoutImage app-page-padding')">
      <AppImagem v-if="(item.imagem != null)" :imagem="item.imagem" :titulo="item.tituloImagem" :subtitulo="item.subtituloImagem" />
      <AppCitacao :titulo="item.titulo" :conteudo="item.conteudo" :class="(item.imagem != null ? 'paddingPagConteudo-withImage mt-6' : 'paddingPagConteudo mt-6')" />
      <div :class="(item.imagem != null ? 'paddingPagConteudo-withImage' : 'paddingPagConteudo') + ' flex flex-column md:flex-row w-full gap-2 mt-4'">
        <div v-if="(item.destaque01 != null)" @click="fc_goRoute('area', { id: item.destaque01 })" class="caixa flex flex-1 flex-column rsa-color-bg-03 text-white px-4 pb-4">
          <div class="flex flex-row align-items-center">
            <div class="text-8xl rsa-font-zilla-bold">\</div>
            <div class="text-xl ml-2" v-html="item.destaque01_titulo"></div>
          </div>
          <div v-html="item.destaque01_descricao"></div>
        </div>
        <div v-if="(item.destaque02 != null)" @click="fc_goRoute('area', { id: item.destaque02 })" class="caixa flex flex-1 flex-column rsa-color-bg-04 text-white px-4 pb-4">
          <div class="flex flex-row align-items-center">
            <div class="text-8xl rsa-font-zilla-bold">\</div>
            <div class="text-xl ml-2" v-html="item.destaque02_titulo"></div>
          </div>
          <div v-html="item.destaque02_descricao"></div>
        </div>
        <div v-if="(item.destaque03 != null)" @click="fc_goRoute('area', { id: item.destaque03 })" class="caixa flex flex-1 flex-column rsa-color-bg-05 text-white px-4 pb-4">
          <div class="flex flex-row align-items-center">
            <div class="text-8xl rsa-font-zilla-bold">\</div>
            <div class="text-xl ml-2" v-html="item.destaque03_titulo"></div>
          </div>
          <div v-html="item.destaque03_descricao"></div>
        </div>
      </div>
      <div v-if="(item.areas != null && item.areas.length > 0)" :class="(item.imagem != null ? 'app-page-padding mt-4' : '')">
        <div class="paddingPagConteudo mt-4">
          <AppTitleX :titulo="fc_getText(null, 'areas', { title: true })" />
          <div class="areas flex flex-column pt-4">
            <div v-for="(forItem, forIndex) in item.areas" :key="forIndex" @click="fc_goRoute('area', { id: forItem.id })" class="area-link flex align-items-center py-3 border-bottom-1 gap-3">
              <div><i class="pi pi-arrow-right rsa-color" style="font-size: 24px"></i></div>
              <div class="rsa-font-montserrat-bold text-xl" v-html="forItem.titulo"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loaded: false,
      item: {},
    };
  },
  props: {},
  computed: {},
  methods: {
    mt_start() {
      this.mt_loadData();
    },
    mt_loadData() {
      this.loaded = false;
      this.fc_fetch({ endpoint: 'AreasPagina/', method: 'GET', success: this.mt_loadData_success, error: () => { this.mt_goBack(); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.item != null) {
        this.item = response.item;
        this.loaded = true;
      } else {
        this.mt_goBack();
      }
    },
    mt_goBack() {
      this.fc_goRoute('home');
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 992px) {
  .paddingPagConteudo-withImage {
    padding: 0vw 8vw 4vw 6vw;
  }
}
@media screen and (min-width: 992px) {
  .paddingPagConteudo-withImage {
    padding: 0vw 8vw 4vw 315px;
  }
}

.caixa {
  cursor: pointer;
}
.caixa:hover {
  background-color: var(--rsa-color-06);
}
.areas {
  padding-left: 2rem;
  padding-right: 2rem;
}
@media screen and (min-width: 768px) {
  .areas {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media screen and (min-width: 992px) {
  .areas {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}
@media screen and (min-width: 1200px) {
  .areas {
    padding-left: 14rem;
    padding-right: 14rem;
  }
}
.area-link {
  cursor: pointer;
}
.area-link:hover div {
  color: var(--rsa-color);
}
</style>
