import { createStore } from 'vuex';

export default createStore({
  state: {
    windowSize: 0,
    classSize: '',
    lang: 'pt',
    dictionary: [],
    dictionary_primevue: {},
    prevRoute: null,
  }
});
