<template>
  <div class="footer flex flex-column lg:flex-row flex-wrap sm:flex-nowrap w-full justify-content-center gap-2 lg:gap-4 xl:gap-1 px-2 lg:px-4 xl:px-8 py-4">
    <div class="flex flex-grow-1 flex-column sm:flex-row flex-wrap sm:flex-nowrap justify-content-center gap-2 lg:gap-4 xl:gap-8">
      <div class="flex flex-grow-1 align-items-center justify-content-center">
        <div @click="fc_goRoute('home')" class="footer-logo flex flex-grow-1 justify-content-center lg:justify-content-start mb-4">
          <img :src="require('@/assets/design/logo_rsa_icon.jpg')" width="270" height="105" />
        </div>
      </div>
      <!-- <div class="hidden sm:flex flex-grow-1 align-items-center justify-content-center">
        <div @click="fc_goRoute('nextlaw')" class="footer-logo flex flex-grow-1 justify-content-center mb-4">
          <div class="nextlaw" :style="'width:260px;height:26px;background-image: url(' + require('@/assets/design/nextlaw.png') + ');'"></div>
        </div>
      </div> -->
    </div>
    <div class="flex flex-grow-1  flex-column sm:flex-row flex-wrap sm:flex-nowrap justify-content-center gap-4 lg:gap-4 xl:gap-8">
      <div class="flex flex-grow-1 flex-column align-items-center lg:align-items-start justify-content-center text-sm gap-1">
        <div @click="fc_goRoute('termosCondicoes')" class="nowrap button-text-white" v-html="fc_getText(null, 'termosCondicoes')"></div>
        <div @click="fc_goRoute('politicaDePrivacidade')" class="nowrap button-text-white" v-html="fc_getText(null, 'politicaDePrivacidade')"></div>
        <div @click="fc_goRoute('politicaDeCookies')" class="nowrap button-text-white" v-html="fc_getText(null, 'politicaDeCookies')"></div>
        <div @click="fc_goRoute('contactos')" class="nowrap button-text-white" v-html="fc_getText(null, 'contactos')"></div>
      </div>
      <div class="flex flex-grow-1 flex-column align-items-center lg:align-items-end justify-content-center text-xs">
        <div class="gap-2" style="display:grid;">
          <div class="nowrap text-white rsa-font-montserrat-light" v-html="fc_getText(null, 'copyright')"></div>
          <div class="nowrap text-white rsa-font-montserrat-light" v-html="fc_getText(null, 'direitos')"></div>
          <div class="nowrap text-white rsa-font-montserrat-light" v-html="fc_getText(null, 'developed')"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.footer {
  background-color: var(--rsa-gray-04);
}

.footer-logo {
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.footer-logo:hover {
  transform: translateX(6%);
  transition: all 0.3s ease 0s;
}

.footer-logo .nextlaw {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
</style>
