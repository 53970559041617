<template>
  <div class="flex w-full mt-8">
    <div class="flex flex-grow-1 justify-content-start">
      <div class="flex align-self-center">
        <div @click="mt_goPrev" :class="'button-prev flex gap-3 align-items-center' + (prevNext.prev == null ? ' disabled' : '')">
          <i class="pi pi-arrow-left" style="font-size: 22px"></i>
          <span class="text-sm rsa-font-montserrat-semibold" v-html="fc_getText(null, 'anterior', { upper: true })"></span>
        </div>
      </div>
    </div>
    <div class="flex flex-grow-1 justify-content-center">
      <div class="flex align-self-center">
        <div @click="mt_goBack" class="button-text-blue text-sm" v-html="fc_getText(null, 'voltar', { upper: true })"></div>
      </div>
    </div>
    <div class="flex flex-grow-1 justify-content-end">
      <div class="flex align-self-center">
        <div @click="mt_goNext" :class="'button-next flex gap-3 align-items-center' + (prevNext.next == null ? ' disabled' : '')">
          <span class="text-sm rsa-font-montserrat-semibold" v-html="fc_getText(null, 'seguinte', { upper: true })"></span>
          <i class="pi pi-arrow-right" style="font-size: 22px"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    prevNext: Object,
    mt_goPrev: Function,
    mt_goNext: Function,
    mt_goBack: Function,
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.button-prev:not(.disabled) {
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease 0s;
}
.button-prev:not(.disabled):hover {
  transform: translateX(-6%);
  transition: all 0.3s ease 0s;
}
.button-prev:not(.disabled):hover i {
  color: var(--rsa-color);
}
.button-prev.disabled {
  user-select: none;
  color: var(--rsa-gray-02);
}

.button-next:not(.disabled) {
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease 0s;
}
.button-next:not(.disabled):hover {
  transform: translateX(6%);
  transition: all 0.3s ease 0s;
}
.button-next:not(.disabled):hover i {
  color: var(--rsa-color);
}
.button-next.disabled {
  user-select: none;
  color: var(--rsa-gray-02);
}
</style>
