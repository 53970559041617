<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <Galleria :value="list" :circular="true" :showItemNavigators="true" :showThumbnails="false" :showIndicators="true">
      <template #item="slotProps">
        <HomeBannerItem :id="slotProps.item.id" :visualizacao="slotProps.item.visualizacao" :imagem="slotProps.item.imagem" :titulo="slotProps.item.titulo" :subtitulo="slotProps.item.subtitulo" :video="slotProps.item.video" />
      </template>
    </Galleria>
  </div>
</template>

<script>
import Galleria from 'primevue/galleria';
import HomeBannerItem from '@/components/HomeBannerItem.vue';
export default {
  components: {
    Galleria,
    HomeBannerItem,
  },
  data() {
    return {
      loaded: false,
      list: [],
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.mt_loadData();
    },
    mt_loadData() {
      this.loaded = false;
      this.fc_fetch({ endpoint: 'Banners/', method: 'GET', success: this.mt_loadData_success });
    },
    mt_loadData_success(response) {
      if (response != null && response.list != null) {
        this.list = response.list;
      }
      this.loaded = true;
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.p-galleria-indicators {
  position: relative;
  top: -60px;
  margin: 0px 80px;
  padding: 1rem 0rem;
  display: none;
}

.p-galleria-item-nav {
  top: auto;
  bottom: 0px;
  margin: 0.2rem;
}
</style>
