<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div :class="(item.imagem != null ? 'app-page-margin-withImage' : 'app-page-margin-withoutImage app-page-padding')">
      <AppImagem v-if="(item.imagem != null)" :imagem="item.imagem" :titulo="item.tituloImagem" :subtitulo="item.subtituloImagem" />
      <div :class="(item.imagem != null ? 'app-page-padding mt-8' : '')">
        <div class="paddingPagConteudo flex flex-column w-full">
          <div v-if="(item.conteudos != null)">
            <div v-for="(forItem, forIndex) in item.conteudos" :key="forIndex" :class="(forIndex > 0 ? 'mt-8' : '')">
              <AppTexto :titulo="forItem.titulo" :conteudo="forItem.descConteudo" :tituloFirstDiv="item.conteudos[0].titulo" class="mt-6" />
            </div>
          </div>
        </div>
        <div v-if="(item.membros != null && item.membros.length > 0)" class="paddingPagConteudo app-page-margin-subpage-second">
          <AppTitleX :titulo="fc_getText(null, 'equipa', { title: true })" />
          <div v-if="(item.coordenadores != null && item.coordenadores.length > 0)">
            <div class="py-2 text-right rsa-font-montserrat-bold border-bottom-1 surface-border" v-html="fc_getText(null, 'coordenacao', { title: true })"></div>
            <div v-for="(forItem, forIndex) in item.coordenadores" :key="forIndex" class="py-2 text-right nomeLink" v-html="forItem.nome" @click="fc_goRoute('membro', { id: forItem.id })" ></div>
          </div>
          <div class="flex flex-wrap w-full gap-3 mt-4">
            <ListItemMembro v-for="(forItem, forIndex) in item.membros" :key="forIndex" :item="forItem" />
          </div>
        </div>
        <div v-if="(item.videos != null && item.videos.length > 0)" class="paddingPagConteudo app-page-margin-subpage-second">
          <AppTitleX :titulo="fc_getText(null, 'videos', { title: true })" />
          <div class="flex flex-wrap w-full gap-3 mt-4">
            <ListItemVideo v-for="(forItem, forIndex) in item.videos" :key="forIndex" :item="forItem" />
          </div>
        </div>
        <div v-if="(item.publicacoes != null && item.publicacoes.length > 0)" class="paddingPagConteudo app-page-margin-subpage-second">
          <AppTitleX :titulo="fc_getText(null, 'publicacoes', { title: true })" />
          <div class="flex flex-wrap w-full gap-3 mt-4">
            <div v-for="(forItem, forIndex) in item.publicacoes" :key="forIndex">
              <ListItemPublicacao :item="forItem" />
            </div>
          </div>
        </div>
        <div v-if="(item.noticias != null && item.noticias.length > 0)" class="paddingPagConteudo app-page-margin-subpage-second">
          <AppTitleX :titulo="fc_getText(null, 'noticias', { title: true })" />
          <div class="flex flex-wrap w-full gap-3 mt-4">
            <ListItemNoticia v-for="(forItem, forIndex) in item.noticias" :key="forIndex" :item="forItem" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListItemMembro from '@/components/ListItemMembro.vue';
import ListItemVideo from '@/components/ListItemVideo.vue';
import ListItemPublicacao from '@/components/ListItemPublicacao.vue';
import ListItemNoticia from '@/components/ListItemNoticia.vue';
export default {
  components: {
    ListItemMembro,
    ListItemVideo,
    ListItemPublicacao,
    ListItemNoticia,
  },
  data() {
    return {
      loaded: false,
      item: {},
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.mt_loadData();
    },
    mt_loadData() {
      this.loaded = false;
      this.fc_fetch({ endpoint: 'ComplianceEmpresarial', method: 'GET', success: this.mt_loadData_success, error: () => { this.mt_goBack(); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.item != null) {
        this.item = response.item;
        this.loaded = true;
      } else {
        this.mt_goBack();
      }
    },
    mt_goBack() {
      this.fc_goRoute('home');
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nomeLink:hover {
  color: var(--rsa-color);
  cursor:pointer;
}
</style>
