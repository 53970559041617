<template>
  <div @click="fc_goRoute('membro', { id: item.id })" class="item flex flex-column">
    <div class="caixa">
      <div class="imagem-caixa">
        <div class="imagem" :style="'background-image: url(' + item.imagemLista + ');'"></div>
      </div>
      <div class="p-2">
        <div class="rsa-font-montserrat-bold mt-2" v-html="item.nome"></div>
        <div class="text-xs" v-html="categoriasDiv"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    item: Object,
  },
  computed: {
    categoriasDiv() {
      let categorias = "";
      if (this.item.categorias.length > 0){
        this.item.categorias.forEach((categoria,index) => {
          categorias += categoria;
          if (index < this.item.categorias.length - 1) {
            categorias += " | ";
          }
        });
        return categorias;
      } else {
        return categorias;
      }
    }
  },
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.item {
  cursor: pointer;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.item:hover {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}
.caixa {
  width: 300px;
}
.imagem-caixa {
  width: 300px;
  height: 300px;
  background-color: black;
}
.imagem-caixa .imagem {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
</style>
