<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div :class="(item.imagem != null ? 'app-page-margin-withImage' : 'app-page-margin-withoutImage app-page-padding')">
      <AppImagem v-if="(item.imagem != null)" class="hideBanner" :imagem="item.imagem" titulo="" subtitulo="" />
      <AppCitacao :class="(item.imagem != null ? 'paddingPagConteudo-withImage mt-6' : 'paddingPagConteudo mt-6')" :titulo="item.titulo" :conteudo="item.conteudo" />
    </div>
    <div v-if="(item.membros != null && item.membros.length > 0)" class="app-page-margin-subpage-second app-page-padding">
      <AppTitleX class='paddingPagConteudo' :titulo="fc_getText(null, 'equipa', { title: true })" />
      <div v-if="(item.coordenadores != null && item.coordenadores.length > 0)" class='paddingPagConteudo'>
        <div class="py-2 text-right rsa-font-montserrat-bold border-bottom-1 surface-border" v-html="fc_getText(null, 'coordenacao', { title: true })"></div>
        <div v-for="(forItem, forIndex) in item.coordenadores" :key="forIndex" class="py-2 text-right nomeLink" v-html="forItem.nome" @click="fc_goRoute('membro', { id: forItem.id })" ></div>
      </div>
      <div class="paddingPagConteudo flex flex-wrap w-full gap-3 mt-4">
        <ListItemMembro v-for="(forItem, forIndex) in item.membros" :key="forIndex" :item="forItem" />
      </div>
    </div>
  </div>
</template>

<script>
import ListItemMembro from '@/components/ListItemMembro.vue';
export default {
  components: {
    ListItemMembro,
  },
  data() {
    return {
      loaded: false,
      id: null,
      item: {},
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.mt_loadData();
    },
    mt_loadData() {
      this.loaded = false;
      this.fc_fetch({ endpoint: 'ServicosRegistosNotariado', method: 'GET', success: this.mt_loadData_success, error: () => { this.mt_goBack(); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.item != null) {
        this.item = response.item;
        this.loaded = true;
      } else {
        this.mt_goBack();
      }
    },
    mt_goBack() {
      this.fc_goRoute('home');
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 992px) {
  .paddingPagConteudo-withImage {
    padding: 0vw 8vw 4vw 6vw;
  }
}
@media screen and (min-width: 992px) {
  .paddingPagConteudo-withImage {
    padding: 0vw 8vw 4vw 315px;
  }
}
@media screen and (max-width: 768px) {
  .hideBanner {
    height: 0vh !important;
  }
}
.caixa-azul {
  padding-left: 8vw;
  padding-right: 8vw;
}
.nomeLink:hover {
  color: var(--rsa-color);
  cursor:pointer;
}
</style>
