<template>
  <div class="mr-6">
    <div class="flex flex-column w-full mb-4">
      <div class="text w-full">
        <div class="text-titulo flex flex-column " v-html="titulo"></div>
      </div>
      <div v-if="(conteudo != null)" class="text w-full flex gap-0">
        <!-- <div class="text-titulo hidden sm:flex flex-grow-0 opacity-0" v-html="firstDiv"></div> -->
        <div class="conteudo pt-4 text-xl" v-html="conteudo"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    titulo: String,
    conteudo: String,
    tituloFirstDiv: String,
  },
  computed: {
    firstDiv() {
      const divs = this.tituloFirstDiv.split('<div>');
      if (divs.length > 1) {
        return '<div>' + divs[divs.length - 1] + '';
      } else {
        return this.tituloFirstDiv;
      }
    },
    titleColor() {
      if (this.color == null) {
        return 'var(--text-color)';
      } else {
        return this.color;
      }
    },
  },
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.text .text-titulo {
  font-family: var(--rsa-font-zilla-semibold);
  font-size: 32px;
  color: v-bind('titleColor');
}
@media screen and (max-width: 992px) {
  .text .text-titulo {
    font-size: 32px;
  }
}
@media screen and (min-width: 576px) {
  .text .text-titulo {
    font-size: 32px;
  }
}
@media screen and (min-width: 768px) {
  .text .text-titulo {
    font-size: 42px;
  }
}
@media screen and (min-width: 992px) {
  .text .text-titulo {
    font-size: 52px;
  }
}
@media screen and (min-width: 1200px) {
  .text .text-titulo {
    font-size: 62px;
  }
}

.text .text-titulo:deep(div) {
  margin-top: -8px;
}
.text .text-titulo:deep(div > span) {
  white-space: nowrap;
}
.text .text-titulo:deep(div:last-child > span) {
  border-bottom: 1px solid var(--text-color);
}
</style>
