<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div v-if="(sended)">
      <div class="text-center mt-8" v-html="fc_getText(null, 'form_recrutamento_sended')"></div>
    </div>
    <div v-if="(!sended)" class="mt-6">
      <FormRecrutamentoFase01 v-if="(fase == 1)" />
      <FormRecrutamentoFase02 v-if="(fase == 2)" />
    </div>
  </div>
</template>

<script>
import FormRecrutamentoFase01 from '@/components/FormRecrutamentoFase01.vue';
import FormRecrutamentoFase02 from '@/components/FormRecrutamentoFase02.vue';
export default {
  components: {
    FormRecrutamentoFase01,
    FormRecrutamentoFase02,
  },
  data() {
    return {
      loaded: false,
      sended: false,
      fase: 1,
      lists: {
        categorias: [], redes: [],
      },
      form: {
        nome: null, email: null, telemovel: null,
        categoria: null, outraCategoria: null, rede: null,
        mensagem: null, cv: null, carta: null,
      },
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.loaded = true;
    },
  },
  created() {},
  mounted() {
    let promises = [];
    promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'RecrutamentoCategorias/', method: 'GET', success: (response) => { this.fc_responseList(response, this.lists.categorias); resolve(); }, error: reject }); }));
    promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'RecrutamentoRedes/', method: 'GET', success: (response) => { this.fc_responseList(response, this.lists.redes); resolve(); }, error: reject }); }));
    Promise.all(promises).then(() => { this.mt_start(); }).catch((e) => { this.fc_printErrors(e); });
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>