<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div :class="(item.imagem != null ? 'app-page-margin-withImage pb-8' : 'app-page-margin-withoutImage pb-8')">
      <AppImagem v-if="(item.imagem != null)" :imagem="item.imagem" :titulo="titulo" :subtitulo="subtitulo" />
      <div :class="(item.imagem != null ? 'pt-8' : '' ) + ' flex flex-column-reverse lg:flex-row w-full'">
        <div class="lg:flex flex-column flex-grow-0">
          <div class="coluna">
            <div class="mb-4">
              <AppTitleColuna class="paddingTituloContactos" :titulo="fc_getText(null, 'contactos', { title: true })" />
            </div>
            <div v-if="(mostrarContactos)" class="columnContactos p-4 lg:p-0 lg:pl-8">
              <div v-if="(item.parceria != null && item.parceria != '')" class="rsa-font-montserrat-bold mt-4" v-html="item.parceria"></div>
              <div v-if="(item.moradaArteria != null && item.moradaArteria != '')" class="mt-4">
                <div class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosMorada')"></div>
                <div v-html="item.moradaArteria"></div>
                <div v-if="((item.moradaCp != null && item.moradaCp != '') || (item.moradaCpAlf != null && item.moradaCpAlf != ''))">
                  <span v-if="(item.moradaCp != null && item.moradaCp != '')" v-html="item.moradaCp"></span>
                  <span v-if="((item.moradaCp != null && item.moradaCp != '') && (item.moradaCpAlf != null && item.moradaCpAlf != ''))">&nbsp;</span>
                  <span v-if="(item.moradaCpAlf != null && item.moradaCpAlf != '')" v-html="item.moradaCpAlf"></span>
                </div>
              </div>
              <div v-if="(mostrarContactosTel)" class="mt-4">
                <div v-if="(item.telefone != null && item.telefone != '')">
                  <span class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosTelefone') + ' '"></span>
                  <span v-html="item.telefone"></span>
                  <span v-if="(item.chamadaRedeFixa != null && item.chamadaRedeFixa != false)">&nbsp;*</span>
                </div>
                <div v-if="(item.telemovel != null && item.telemovel != '')">
                  <span class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosTelemovel') + ' '"></span>
                  <span v-html="item.telemovel"></span>
                </div>
                <div v-if="(item.telemovelAlternativo != null && item.telemovelAlternativo != '')">
                  <span class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosTelemovel') + ' '"></span>
                  <span v-html="item.telemovelAlternativo"></span>
                </div>
                <div v-if="(item.fax != null && item.fax != '')">
                  <span class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosFax') + ' '"></span>
                  <span v-html="item.fax"></span>
                </div>
                <div v-if="(item.chamadaRedeFixa != null && item.chamadaRedeFixa != false)" class="mt-2" v-html="fc_getText(null, 'chamadaRedeFixa')"></div>
              </div>
              <div v-if="(item.email != null && item.email != '')" class="mt-4">
                <div class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosEmail') + ' '"></div>
                <div class="limiteTexto">
                  <a :href="'mailto:' + item.email" target="_blank" v-html="item.email"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-column flex-grow-1">
          <div class="flex flex-column gap-4 mt-4">
            <div class="margin-columnContent pl-4 pr-6 lg:pl-0 lg:pr-4">
              <AppCitacao :titulo="(item.imagem != null) ? '' : item.titulo" :conteudo="item.conteudo" />
            </div>
          </div>
        </div>
      </div>
      <div class="padding-columnContent flex flex-column-reverse">
        <div v-if="(item.membros != null && item.membros.length > 0)" class="mt-6">
          <AppTitleX :titulo="fc_getText(null, 'equipa', { title: true })" />
          <div class="flex flex-wrap w-full gap-3 mt-4">
            <ListItemMembro v-for="(forItem, forIndex) in item.membros" :key="forIndex" :item="forItem" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListItemMembro from '@/components/ListItemMembro.vue';
export default {
  components: {
    ListItemMembro,
  },
  data() {
    return {
      loaded: false,
      id: null,
      item: {},
    };
  },
  props: {},
  computed: {
    titulo() {
      return this.item.titulo;
    },
    subtitulo() {
      if (this.item.tipoRede == 1) { return null; }
      else if (this.item.tipoRede == 2) { return this.fc_getText(null, 'parceriasNacionais'); }
      else if (this.item.tipoRede == 3) { return this.fc_getText(null, 'parceriasInternacionais'); }
      else if (this.item.tipoRede == 4) { return this.fc_getText(null, 'outrasParcerias'); }
      else { return null; }
    },
    mostrarContactos() {
      if (
        (this.item.moradaArteria != null && this.item.moradaArteria != '') ||
        (this.item.telefone != null && this.item.telefone != '') ||
        (this.item.telemovel != null && this.item.telemovel != '') ||
        (this.item.fax != null && this.item.fax != '') ||
        (this.item.email != null && this.item.email != '')
      ) {
        return true;
      } else {
        return false;
      }
    },
    mostrarContactosTel() {
      if (
        (this.item.telefone != null && this.item.telefone != '') ||
        (this.item.telemovel != null && this.item.telemovel != '') ||
        (this.item.fax != null && this.item.fax != '')
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    '$route.params'(val) {
      if (this.$route.name == 'rede') {
        this.id = null;
        this.item = {};
        this.mt_start();
      }
    },
  },
  methods: {
    mt_start() {
      this.id = this.$route.params.id;
      if (this.id == null) { this.mt_goBack(); }
      else { this.mt_loadData(); }
    },
    mt_loadData() {
      this.loaded = false;
      this.fc_fetch({ endpoint: 'Redes/' + this.id + '/', method: 'GET', success: this.mt_loadData_success, error: () => { this.mt_goBack(); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.item != null) {
        this.item = response.item;
        this.loaded = true;
      } else {
        this.mt_goBack();
      }
    },
    mt_goBack() {
      this.fc_goRoute('home');
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.coluna {
  width: 308px;
  padding-top: 2vh;
  padding-right: 30px;
}
@media screen and (max-width: 992px) {
  .padding-columnContent {
    padding: 0vw 8vw 8vw 8vw;
  }
  .coluna,
  .padding-columnContent {
    padding-left: 1.5rem;
  }
  .columnContactos {
    padding: 0px 0px !important;
  }
  .margin-columnContent {
    margin-right: 15px;
  }
}
@media screen and (min-width: 992px) {
  .padding-columnContent {
    padding-left: 315px;
  }
  .margin-columnContent {
    margin-right: 30px;
  }
  .paddingTituloContactos {
    padding-top: 23px;
  }
}
@media screen and (min-width: 1200px) {
  .paddingTituloContactos {
    padding-top: 35px;
  }
}
.limiteTexto {
  word-wrap: break-word;
}
</style>
