<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div class="flex flex-column-reverse lg:flex-row w-full">
      <div class="hidden lg:flex flex-column flex-grow-0">
        <div class="coluna">
          <div class="mb-4">
            <AppTitleColuna :titulo="fc_getText(null, 'publicacoes', { title: true })" />
          </div>
          <div class="p-4 lg:p-0 lg:pl-8">
            <div class="flex flex-column gap-1 mt-4">
              <!-- <div @click="fc_goRoute('')" class="botaoPublicacaoPagina text-xl mt-2" v-html="fc_getText(null, 'legalAlerts', { title: true })"></div> -->
              <div @click="fc_goRoute('legalAlerts')" class="botaoPublicacaoPagina text-lg mt-2" v-html="fc_getText(null, 'legalAlerts', { title: true })"></div>
              <div @click="fc_goRoute('ebooks')" class="botaoPublicacaoPagina text-xl mt-2" v-html="fc_getText(null, 'ebooks', { title: true })"></div>
              <div @click="fc_goRoute('outrasPublicacoes')" class="botaoPublicacaoPagina text-lg mt-2" v-html="fc_getText(null, 'outrasPublicacoes', { title: true })"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="app-page-margin-withoutImage publicacoesPagina flex flex-column flex-grow-1">
        <AppCitacao :titulo="item.titulo" :conteudo="item.subtitulo"/>
        <div v-if="(!list.loaded)"><AppSpinner /></div>
        <DataView v-if="(list.loaded)"
          ref="dv" dataKey="id" class="lista" :layout="list.layout"
          lazy :value="list.list" :rows="list.params.rows" :first="list.params.first" :totalRecords="list.totalRecords"
          :sortField="list.params.sortField" :sortOrder="list.params.sortOrder"
          :paginator="true" :alwaysShowPaginator="false" paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink"
          :currentPageReportTemplate="'{currentPage} / {totalPages}'"
          @page="mt_onPage($event)"
        >
          <!-- no records -->
          <template #empty>
            <div class="text-center mt-8" v-html="fc_getText(null, 'zeroLivros', { first: true })"></div>
          </template>
          <!-- grid layout -->
          <template #grid="slotProps">
            <div class="flex flex-wrap w-full gap-3 mt-8">
              <ListItemLivro v-for="(forItem, forIndex) in slotProps.items" :key="forIndex" :item="forItem" />
            </div>
          </template>
        </DataView>
      </div>
    </div>
  </div>
</template>

<script>
import DataView from 'primevue/dataview';
import ListItemLivro from '@/components/ListItemLivro.vue';
export default {
  components: {
    DataView,
    ListItemLivro,
  },
  data() {
    return {
      loaded: false,
      item: {},
      list: {
        loaded: false,
        layout: 'grid',
        filtersView: false,
        sort: 'dataPublicacao',
        totalRecords: 0,
        list: [],
        params: {
          first: 0,
          rows: 12,
          sortField: 'dataPublicacao',
          sortOrder: -1,
          filters: null,
        },
        filters: {},
      },
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.loaded = true;
      this.mt_queryGet();
      this.mt_loadData();
    },
    mt_loadData() {
      this.list.loaded = false;
      this.fc_fetch({ endpoint: 'PublicacoesPorTipo/3/?tableParams=' + encodeURIComponent(JSON.stringify(this.list.params)), method: 'GET', success: this.mt_loadData_success, error: () => { this.fc_goRoute('home'); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.list != null) {
        this.list.list = response.list;
        this.list.totalRecords = response.pagination.total;
      }
      this.list.loaded = true;
    },
    mt_onPage(data) {
      this.list.params.first = data.first;
      this.mt_querySet();
      this.mt_loadData();
    },
    mt_queryGet() {
      let first = this.fc_getUrlParam('first');
      if (first != null && !isNaN(first) && (Number(first) % this.list.params.rows) == 0) {
        this.list.params.first = Number(first);
      }
    },
    mt_querySet() {
      let query = {
        first: this.list.params.first,
      };

      this.$router.replace({ name: this.$route.name, query: query });
    },
  },
  created() {},
  mounted() {
    let promises = [];
    promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'PublicacoesPorTipoPagina/3/', method: 'GET', success: (response) => { this.item = response.item; resolve(); }, error: reject }); }));
    Promise.all(promises).then(() => { this.mt_start(); }).catch((e) => { this.fc_printErrors(e); });
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lista:deep(.p-paginator-current) {
  font-size: 1.5rem;
  color: #000;
  border: none;
  background: none;
  cursor: default;
}
.lista:deep(.p-paginator) {
  margin-top: 20px;
}
.lista:deep(.p-paginator .p-paginator-prev) {
  font-size: 1.5rem;
  border: none;
  background: none;
  color: var(--rsa-color);
}
.lista:deep(.p-paginator .p-paginator-prev:hover) {
  color: var(--rsa-gray-04);
}
.lista:deep(.p-paginator .p-paginator-next) {
  font-size: 1.5rem;
  border: none;
  background: none;
  color: var(--rsa-color);
}
.lista:deep(.p-paginator .p-paginator-next:hover) {
  color: var(--rsa-gray-04);
}
.lista:deep(.p-paginator .p-icon) {
  width: 2rem;
  height: 2rem;
}
.lista:deep(.p-paginator .p-disabled) {
  color: var(--rsa-gray-02);
}

.coluna {
  width: 308px;
  padding-top: 2vh;
}
@media screen and (min-width: 992px) {
  .coluna {
    padding-top: 244px;
  }
  .publicacoesPagina {
    padding: 0vw 12vw 8vw 1vw;
  }
}
@media screen and (max-width: 992px) {
  .publicacoesPagina {
    padding: 0vw 12vw 8vw 6vw
  }
}
.botaoPublicacaoPagina:hover {
    cursor:pointer;
    color: var(--rsa-color);
}
</style>
