<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div class="grid">
      <div class="col-12 field required">
        <span class="label" v-html="fc_getText(null, 'form_categoria')"></span>
        <Dropdown inputId="categoria" :options="lists.categorias" v-model="form.categoria" optionValue="id" optionLabel="descricao" optionDisabled="disabled" showClear :inputClass="'w-full' + (v$.form.categoria.$errors.length ? ' p-invalid' : '')" :placeholder="fc_getText(null, 'form_selecione')" />
        <small v-if="(v$.form.categoria.$errors[0])" class="rsa-color" v-html="v$.form.categoria.$errors[0].$message"></small>
      </div>
    </div>
    <div class="grid" v-if="(outraCategoria_requiredIf)">
      <div class="col-12 field required">
        <label for="outraCategoria" v-html="fc_getText(null, 'form_outraCategoria')"></label>
        <InputText id="outraCategoria" v-model="form.outraCategoria" :class="'w-full' + (v$.form.outraCategoria.$errors.length ? ' p-invalid' : '')" />
        <small v-if="(v$.form.outraCategoria.$errors[0])" class="rsa-color" v-html="v$.form.outraCategoria.$errors[0].$message"></small>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 field required">
        <span class="label" v-html="fc_getText(null, 'form_rede')"></span>
        <Dropdown inputId="rede" :options="lists.redes" v-model="form.rede" optionValue="id" optionLabel="descricao" optionDisabled="disabled" showClear :inputClass="'w-full' + (v$.form.rede.$errors.length ? ' p-invalid' : '')" :placeholder="fc_getText(null, 'form_selecione')" />
        <small v-if="(v$.form.rede.$errors[0])" class="rsa-color" v-html="v$.form.rede.$errors[0].$message"></small>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 field required">
        <span class="label" v-html="fc_getText(null, 'form_cv')"></span>
        <FileUpload mode="basic" accept="application/pdf" :maxFileSize="20000000" @select="mt_onFileSelectCv($event)" customUpload @uploader="mt_onFileSelectCv(null)" :class="'w-full' + (v$.form.cv.$errors.length ? ' p-invalid' : '')" />
        <small v-if="(v$.form.cv.$errors[0])" class="rsa-color" v-html="v$.form.cv.$errors[0].$message"></small>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 field required">
        <span class="label" v-html="fc_getText(null, 'form_carta')"></span>
        <FileUpload mode="basic" accept="application/pdf" :maxFileSize="20000000" @select="mt_onFileSelectCarta($event)" customUpload @uploader="mt_onFileSelectCarta(null)" :class="'w-full' + (v$.form.carta.$errors.length ? ' p-invalid' : '')" />
        <small v-if="(v$.form.carta.$errors[0])" class="rsa-color" v-html="v$.form.carta.$errors[0].$message"></small>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 field">
        <label for="mensagem" v-html="fc_getText(null, 'form_mensagem')"></label>
        <Textarea id="mensagem" v-model="form.mensagem" :class="'w-full' + (v$.form.mensagem.$errors.length ? ' p-invalid' : '')" rows="5" style="resize:none;" />
        <small v-if="(v$.form.mensagem.$errors[0])" class="rsa-color" v-html="v$.form.mensagem.$errors[0].$message"></small>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 md:col-3 md:col-offset-6 xl:col-2 xl:col-offset-8">
        <Button @click="mt_formAnterior()" :label="fc_getText(null, 'form_anterior')" class="w-full" severity="secondary" />
      </div>
      <div class="col-12 md:col-3 xl:col-2">
        <Button @click="mt_formSubmeter()" :label="fc_getText(null, 'form_submeter', { title: true })" class="button-rsaColor w-full" />
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
export default {
  setup () { return { v$: useVuelidate() }; },
  components: {},
  data() {
    return {
      loaded: false,
      lists: this.$parent.lists,
      form: {
        categoria: null, outraCategoria: null, rede: null,
        mensagem: null, cv: null, carta: null,
      },
    };
  },
  validations () {
    return {
      form: {
        categoria: {
          required: helpers.withMessage(this.fc_getText(null, 'field_required', { first: true }), required),
        },
        outraCategoria: {
          requiredIf: helpers.withMessage(this.fc_getText(null, 'field_required', { first: true }), requiredIf(() => { return this.outraCategoria_requiredIf; })),
        },
        rede: {
          required: helpers.withMessage(this.fc_getText(null, 'field_required', { first: true }), required),
        },
        mensagem: {},
        cv: {
          required: helpers.withMessage(this.fc_getText(null, 'field_required', { first: true }), required),
        },
        carta: {
          required: helpers.withMessage(this.fc_getText(null, 'field_required', { first: true }), required),
        },
      },
    }
  },
  props: {},
  computed: {
    outraCategoria_requiredIf() {
      return (this.form.categoria != null && this.form.categoria == 0);
    },
  },
  watch: {
    'form.categoria'(val) {
      if (!(this.form.categoria != null && this.form.categoria == 0)) {
        this.form.outraCategoria = null;
      }
    },
  },
  methods: {
    mt_start() {
      this.form.categoria = this.$parent.form.categoria;
      this.form.outraCategoria = this.$parent.form.outraCategoria;
      this.form.rede = this.$parent.form.rede;
      this.form.mensagem = this.$parent.form.mensagem;
      this.form.cv = this.$parent.form.cv;
      this.form.carta = this.$parent.form.carta;
      this.loaded = true;
    },
    mt_formAnterior() {
      this.form.categoria = null;
      this.form.outraCategoria = null;
      this.form.rede = null;
      this.form.mensagem = null;
      this.form.cv = null;
      this.form.carta = null;

      this.$parent.form.categoria = null;
      this.$parent.form.outraCategoria = null;
      this.$parent.form.rede = null;
      this.$parent.form.mensagem = null;
      this.$parent.form.cv = null;
      this.$parent.form.carta = null;

      this.$parent.fase = 1;
    },
    async mt_formSubmeter() {
      const form_valid = await this.v$.$validate();
      if (form_valid) {
        this.loaded = false;

        this.$parent.form.categoria = this.form.categoria;
        this.$parent.form.outraCategoria = this.form.outraCategoria;
        this.$parent.form.rede = this.form.rede;
        this.$parent.form.mensagem = this.form.mensagem;
        this.$parent.form.cv = this.form.cv;
        this.$parent.form.carta = this.form.carta;

        let formData = new FormData();
        for (let key in this.$parent.form) {
          if (key != 'cv' && key != 'carta' && this.$parent.form[key] != null) { formData.append(key, this.$parent.form[key]); }
        }
        for (let key in this.$parent.form.cv) {
          formData.append('cv', this.$parent.form.cv[key]);
        }
        for (let key in this.$parent.form.carta) {
          formData.append('carta', this.$parent.form.carta[key]);
        }

        this.fc_fetch({ endpoint: 'RecrutamentoPedido/', method: 'POST', body: formData, formData: true, success: this.mt_formSubmeter_success, error: () => { this.loaded = true; } });
      }
    },
    mt_formSubmeter_success() {
      Object.keys(this.$parent.form).forEach(key => {
        this.$parent.form[key] = null;
      });
      this.$parent.sended = true;
    },
    mt_onFileSelectCv(data) {
      this.form.cv = null;
      if (data != null && data.files != null && data.files.length > 0) {
        this.form.cv = data.files;
      }
    },
    mt_onFileSelectCarta(data) {
      this.form.carta = null;
      if (data != null && data.files != null && data.files.length > 0) {
        this.form.carta = data.files;
      }
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
