<template>
<div v-if="(!loaded)"><AppSpinner /></div>
<div v-if="(loaded)" id="app-content">
  <div class="fixed w-full top-0 left-0 z-5">
    <div class="flex flex-column lg:flex-row">
      <div @click="fc_goRoute('home')" class="logo flex cursor-pointer">
        <img :src="require('@/assets/design/logo_rsa.jpg')" class="imagemLogo" />
      </div>
      <div class="flex flex-grow-1">
        <MenuPrincipal :menus="menus" />
      </div>
    </div>
  </div>
  <div class="app-page">
    <div class="absolute z-3 translateAlert hidden sm:block" v-html="fc_getText(null, 'translateAlert')"></div>
    <router-view :key="$route.name" />
  </div>
  <AppFooter />
  <MenuSocialMedia :linksSocialMedia="linksSocialMedia" />
  <ScrollTop />
  <DynamicDialog />
  <ConfirmDialog>
    <template #message="slotProps">
      <div class="flex align-items-start p-4">
        <i :class="slotProps.message.icon + ' mt-1'" style="font-size: 1.5rem"></i>
        <span class="pl-3" v-html="slotProps.message.message"></span>
      </div>
    </template>
  </ConfirmDialog>
  <Toast>
    <template #message="slotProps">
      <span class="p-icon p-toast-message-icon"><i :class="'pi ' + fc_getIconSeverity(slotProps.message.severity) + ' text-4xl'"></i></span>
      <div class="p-toast-message-text">
        <span class="p-toast-summary" v-html="slotProps.message.summary"></span>
        <div class="p-toast-detail" v-html="slotProps.message.detail" />
      </div>
    </template>
  </Toast>
</div>
</template>

<script>
import { usePrimeVue } from 'primevue/config';
import txt_primevue from '@/common/json/txt_primevue.json';
import ScrollTop from 'primevue/scrolltop';
import MenuPrincipal from '@/components/MenuPrincipal.vue';
import MenuSocialMedia from '@/components/MenuSocialMedia.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  components: {
    ScrollTop,
    MenuPrincipal,
    MenuSocialMedia,
    AppFooter,
  },
  data() {
    return {
      loaded: false,
      menus: {},
      linksSocialMedia: {},
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.loaded = true;
      if (this.$cookies.get('newsletter') == null) {
        this.$cookies.set('newsletter', 'true', '1m', '/', '', true);
        setTimeout(() => {
          this.fc_dialogNewsletterBt();
        }, 600);
      }
    },
  },
  created() {
    this.$store.state.lang = this.fc_getLang();

    const primevue = usePrimeVue();
    if (this.$store.state.lang === 'pt') {
      this.$store.state.dictionary_primevue = txt_primevue.pt;
      primevue.config.locale = txt_primevue.pt;
    } else if (this.$store.state.lang === 'en') {
      this.$store.state.dictionary_primevue = txt_primevue.en;
      primevue.config.locale = txt_primevue.en;
    } else if (this.$store.state.lang === 'fr') {
      this.$store.state.dictionary_primevue = txt_primevue.fr;
      primevue.config.locale = txt_primevue.fr;
    } else if (this.$store.state.lang === 'es') {
      this.$store.state.dictionary_primevue = txt_primevue.es;
      primevue.config.locale = txt_primevue.es;
    } else if (this.$store.state.lang === 'de') {
      this.$store.state.dictionary_primevue = txt_primevue.de;
      primevue.config.locale = txt_primevue.de;
    } else if (this.$store.state.lang === 'it') {
      this.$store.state.dictionary_primevue = txt_primevue.it;
      primevue.config.locale = txt_primevue.it;
    }

    this.$router.beforeEach((to, from, next) => {
      this.$store.state.prevRoute = from;
      next();
    });
  },
  mounted() {
    let promises = [];
    promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'TextosApp/', method: 'GET', success: (response) => { this.fc_responseList(response, this.$store.state.dictionary); resolve(); }, error: reject }); }));
    promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'Menus/', method: 'GET', success: (response) => { this.menus = response.item; resolve(); }, error: reject }); }));
    promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'LinksSocialMedia/', method: 'GET', success: (response) => { this.linksSocialMedia = response.item; resolve(); }, error: reject }); }));
    Promise.all(promises).then(() => { this.mt_start(); }).catch((e) => { this.fc_printErrors(e); });
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
@import '@/common/css/vars.css';
@import '@/common/css/layout.css';
</style>

<style scoped>
.logo {
  padding: 20px 30px;
  background: linear-gradient(#000, #000);
}
@media screen and (min-width: 992px) {
  .logo {
    width: 308px;
  }
}
.imagemLogo {
  width: 248px;
  height: 112px;
}
@media screen and (max-height: 600px) and (max-width: 992px) {
  .imagemLogo {
    width: 155px;
    height: 70px;
  }
}
</style>
