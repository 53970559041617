<template>
  <div v-if="(!loaded || list.length > 0)" class="app-page-padding">
    <div v-if="(!loaded)"><AppSpinner /></div>
    <div v-if="(loaded)" class="paddingPagConteudo">
      <AppTitleX :titulo="fc_getText(null, 'eventos', { title: true })" />
      <ListItemEvento v-for="(forItem, forIndex) in list" :key="forIndex" :item="forItem" />
      <div class="flex justify-content-end mt-4 sm:mt-8">
        <div @click="fc_goRoute('eventos')" class="rsa-button-black" v-html="fc_getText(null, 'verMais')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ListItemEvento from '@/components/ListItemEvento.vue';
export default {
  components: {
    ListItemEvento,
  },
  data() {
    return {
      loaded: false,
      list: [],
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.loaded = true;
    },
  },
  created() {},
  mounted() {
    let promises = [];
    promises.push(new Promise((resolve, reject) => { this.fc_fetch({ endpoint: 'EventosHome/', method: 'GET', success: (response) => { this.fc_responseList(response, this.list); resolve(); }, error: reject }); }));
    Promise.all(promises).then(() => { this.mt_start(); }).catch((e) => { this.fc_printErrors(e); });
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped></style>
