<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div class="app-page-margin-withoutImage app-page-padding">
      <div class="flex flex-column align-items-center">
        <div class="mt-4"><i class="pi pi-exclamation-triangle text-red-700 text-8xl"></i></div>
        <div class="mt-4 rsa-font-montserrat-bold text-red-700 text-2xl" v-html="title"></div>
        <div class="mt-2 text-center" v-html="message"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loaded: false,
      title: '',
      message: '',
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      let type = this.fc_getUrlParam('type');
      if (type == null || type == '') { type = 'erroGenerico'; }
      this.title = this.fc_getText(null, type + '_title', { upper: true });
      this.message = this.fc_getText(null, type + '_msg', { first: true });
      this.loaded = true;
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
