<template>
  <div @click="mt_go()" class="item flex flex-column">
    <div class="caixa">
      <div class="imagem-caixa">
        <div :class="'imagem ' + ((item.delimitarImagem == 'True') ? 'imagem-border' : '')" :style="'background-image: url(' + (item.imagemPersonalizada == null ? item.imagemLista : item.imagemPersonalizada)  + ');'"></div>
      </div>
      <div class="p-2">
        <div class="text-sm rsa-font-montserrat-bold mt-2 rsa-color" v-html="fc_formatDate(item.dataPublicacao)"></div>
        <div class="rsa-font-montserrat-bold mt-2" v-html="item.titulo"></div>
        <div class="text-xs" v-html="item.subtitulo"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    item: Object,
  },
  computed: {},
  watch: {},
  methods: {
    mt_go() {
      if (this.item.visualizacao == 2) {
        window.open(this.item.linkPublicacaoExterna, '_blank', 'noreferrer');
      } else if (this.item.visualizacao == 3) {
        this.fc_dialogPdf(this.item.anexo, this.item.anexoNome.toUpperCase());
      } else if (this.item.visualizacao == 4) {
        this.fc_dialogPlayer(this.item.titulo, this.item.linkPlayer);
      } else {
        this.fc_goRoute('newsletter', { id: this.item.id });
      }
    },
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.item {
  cursor: pointer;
}
.item:hover {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.caixa {
  width: 300px;
}
.imagem-caixa {
  width: 300px;
  height: 169px;
  background-color: black;
}
.imagem-caixa .imagem {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.item:hover .imagem-caixa .imagem {
  opacity: 0.8;
}
.imagem-border {
  border: 1px solid black;
}
</style>
