<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div :class="'blackBackground ' + (item.imagem != null ? 'app-page-margin-withImage' : 'app-pageAwards-padding-withoutImage app-page-padding')">
      <AppImagem v-if="(item.imagem != null)" :imagem="item.imagem" :titulo="item.tituloImagem" :subtitulo="item.subtituloImagem" />
      <AppCitacao :titulo="item.titulo" :conteudo="item.conteudo" :class="(item.imagem != null ? 'paddingPagConteudo-withImage mt-6' : 'paddingPagConteudo mt-6') + ' citacaoBlack'" />
      <div v-if="(item.rsa != null && item.rsa.length > 0)" :class="(item.imagem != null ? 'paddingPagConteudo-withImage mt-6' : 'paddingPagConteudo mt-6')">
        <!-- <AppTitleX :titulo="fc_getText(null, 'rsaLp')" /> -->
        <div v-for="(forItem, forIndex) in item.rsa" :key="forIndex">
            <div v-if="(forIndex % 2 == 0)" class="flex">
              <div class="flex left-award mb-3">
                <div class="imagem-caixa flex">
                  <div class="imagem" :style="'background-image: url(' + forItem.imagem + ');'"></div>
                </div>
                <div :class="'premio-caixa pl-3 flex flex-column w-full ' + (forItem.visualizacao == 2 || forItem.visualizacao == 3 ? 'premio-link ' : '')" @click="mt_go(forItem, forItem.visualizacao)">
                  <div class="rsa-font-montserrat-bold pb-1" v-html="forItem.titulo"></div>
                  <div class="text-sm" v-html="forItem.descricao"></div>
                </div>
              </div>
            </div>
            <div v-if="(forIndex % 2 != 0)" class="flex justify-content-end">
              <div class="flex right-award  mb-3">
                <div class="imagem-caixa flex">
                  <div class="imagem" :style="'background-image: url(' + forItem.imagem + ');'"></div>
                </div>
                <div :class="'premio-caixa pl-3 flex flex-column w-full ' + (forItem.visualizacao == 2 || forItem.visualizacao == 3 ? 'premio-link ' : '')" @click="mt_go(forItem, forItem.visualizacao)">
                  <div class="rsa-font-montserrat-bold pb-1" v-html="forItem.titulo"></div>
                  <div class="text-sm" v-html="forItem.descricao"></div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div v-if="(item.membros != null && item.membros.length > 0)" :class="(item.imagem != null ? 'paddingPagConteudo-withImage mt-6' : 'paddingPagConteudo mt-6')">
        <AppTitleX :titulo="fc_getText(null, 'advogados', { title: true })" />
        <div v-for="(forItem, forIndex) in item.membros" :key="forIndex" :class="'premios flex flex-column pt-4' + (forIndex > 0 ? ' mt-4' : '')">
          <div class="xs:text-sm md:text-sm lg:text-xl xl:text-xl rsa-font-montserrat-bold" v-html="forItem.nome"></div>
          <div v-for="(for2Item, for2Index) in forItem.premios" :key="for2Index" @click="mt_go(for2Item, (for2Item.link != null && for2Item.link != '' ? 2 : 1))" :class="(for2Item.link != null && for2Item.link != '' ? 'premio-link ' : '') + 'flex align-items-center py-3 border-bottom-1 gap-3'">
            <div><i class="pi pi-arrow-right rsa-color" style="font-size: 24px"></i></div>
            <div class="xs:text-sm md:text-sm lg:text-xl xl:text-xl" v-html="for2Item.descricao"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loaded: false,
      item: {},
    };
  },
  props: {},
  computed: {},
  methods: {
    mt_start() {
      this.mt_loadData();
    },
    mt_loadData() {
      this.loaded = false;
      this.fc_fetch({ endpoint: 'Premios/', method: 'GET', success: this.mt_loadData_success, error: () => { this.mt_goBack(); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.item != null) {
        this.item = response.item;
        this.loaded = true;
      } else {
        this.mt_goBack();
      }
    },
    mt_go(item, visualizacao) {
      if (visualizacao == 2) {
        window.open(item.link, '_blank', 'noreferrer');
      } else if (visualizacao == 3) {
        this.fc_dialogPdf(item.anexo, item.anexoNome.toUpperCase());
      }
    },
    mt_goBack() {
      this.fc_goRoute('home');
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 992px) {
  .paddingPagConteudo-withImage {
    padding: 0vw 8vw 4vw 6vw;
  }
}
@media screen and (min-width: 992px) {
  .paddingPagConteudo-withImage {
    padding: 0vw 8vw 4vw 315px;
  }
}

.premios {
  padding-left: 2rem;
}
@media screen and (min-width: 768px) {
  .premios {
    padding-left: 4rem;
  }
}
@media screen and (min-width: 992px) {
  .premios {
    padding-left: 8rem;
  }
}
@media screen and (min-width: 1200px) {
  .premios {
    padding-left: 14rem;
  }
}
.premio-link {
  cursor: pointer;
}
.premio-link:hover div {
  color: black;
}
.blackBackground {
  background-color: black;
  color: white;
}
.blackBackground:deep(.titulo) {
  color: white !important;
}
.citacao.citacaoBlack {
  background-image: url('@/assets/design/citacaoBlack.png');
}
.app-page-margin-withImage {
  margin-bottom: 0px;
  padding-bottom: 60px;
}
.app-pageAwards-padding-withoutImage {
  padding-top: 244px;
}
@media screen and (min-width: 992px) {
  .app-page-margin-withImage {
    margin-bottom: 0px;
    padding-bottom: 60px;
  }
}
.imagem-caixa {
  width: 250px;
  height: 110px;
  background-color: black;
}
.premio-caixa {
  padding: 1vw 1vw 1vw 1vw;
  margin-left: 10px;
  background-color: var(--rsa-color);
}
.imagem-caixa .imagem {
  width: 250px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top right ;
  background-size: auto 100%;
}
.left-award {
  width: 65%;
}
.right-award {
  width: 65%;
}
@media screen and (max-width:1200px){
  .left-award,
  .right-award {
    width:80%;
  }
}
@media screen and (max-width:992px){
  .left-award,
  .right-award {
    width:100%;
  }
}
@media screen and (max-width:576px){
  .imagem-caixa {
    width: 150px;
    height: 70px;
  }
  .imagem-caixa .imagem {
    width: 130px;
    background-position: center ;
    background-size: auto 100%;
  }
}
</style>
