import { createRouter, createWebHistory } from 'vue-router';

import HomeView from './views/HomeView.vue';
import ErrorView from './views/ErrorView.vue';
import NotFoundView from './views/NotFoundView.vue';
import EmDesenvolvimentoView from './views/EmDesenvolvimentoView.vue';
import ConteudoGeralView from './views/ConteudoGeralView.vue';
import ContactosView from './views/ContactosView.vue';
import SobreNosView from './views/SobreNosView.vue';
import PremiosView from './views/PremiosView.vue';
import RecrutamentoView from './views/RecrutamentoView.vue';
import RsolView from './views/RsolView.vue';
import RsolNoticiaView from './views/RsolNoticiaView.vue';
import RedeView from './views/RedeView.vue';
import AreasView from './views/AreasView.vue';
import AreaView from './views/AreaView.vue';
import InvestigacaoDesenvolvimentoView from './views/InvestigacaoDesenvolvimentoView.vue';
import ComplianceEmpresarialView from './views/ComplianceEmpresarialView.vue';
import EquipaView from './views/EquipaView.vue';
import EquipaMembroView from './views/EquipaMembroView.vue';
import ConhecimentoView from './views/ConhecimentoView.vue';
import EventosView from './views/EventosView.vue';
import EventoView from './views/EventoView.vue';
import NoticiasView from './views/NoticiasView.vue';
import NoticiaView from './views/NoticiaView.vue';
import VideosView from './views/VideosView.vue';
import VideoView from './views/VideoView.vue';
import PublicacoesView from './views/PublicacoesView.vue';
import PublicacaoView from './views/PublicacaoView.vue';
import NewslettersView from './views/NewslettersView.vue';
import NewsletterView from './views/NewsletterView.vue';
import LegalAlertsView from './views/LegalAlertsView.vue';
import LegalAlertView from './views/LegalAlertView.vue';
import EbooksView from './views/EbooksView.vue';
import EbookView from './views/EbookView.vue';
import LivrosView from './views/LivrosView.vue';
import LivroView from './views/LivroView.vue';
import OutrasPublicacoesView from './views/OutrasPublicacoesView.vue';
import OutraPublicacaoView from './views/OutraPublicacaoView.vue';
import DesksView from './views/DesksView.vue';
import FocoEmpresa from './views/FocoEmpresaView.vue';
import RegistosNotariado from './views/RegistosNotariadoView.vue';

let base = '/';
// let base = '/testes/rsa/';

export const router = createRouter({
  history: createWebHistory(),
  base: base,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 };
  },
  routes: [
    {
      path: base,
      name: 'home',
      component: HomeView,
    },
    {
      path: base + 'error',
      name: 'error',
      component: ErrorView,
    },
    {
      path: base + 'termosCondicoes',
      name: 'termosCondicoes',
      component: ConteudoGeralView,
    },
    {
      path: base + 'politicaDePrivacidade',
      name: 'politicaDePrivacidade',
      component: ConteudoGeralView,
    },
    {
      path: base + 'politicaDeCookies',
      name: 'politicaDeCookies',
      component: ConteudoGeralView,
    },
    {
      path: base + 'contactos',
      name: 'contactos',
      component: ContactosView,
    },
    {
      path: base + 'nextlaw',
      name: 'nextlaw',
      component: ConteudoGeralView,
    },
    {
      path: base + 'rsa',
      name: 'rsa',
      component: SobreNosView,
    },
    {
      path: base + 'premios',
      name: 'premios',
      component: PremiosView,
    },
    {
      path: base + 'recrutamento',
      name: 'recrutamento',
      component: RecrutamentoView,
    },
    {
      path: base + 'rsol',
      name: 'rsol',
      component: RsolView,
    },
    {
      path: base + 'rsolnoticia/:id',
      name: 'rsolnoticia',
      component: RsolNoticiaView,
    },
    {
      path: base + 'rede/:id',
      name: 'rede',
      component: RedeView,
    },
    {
      path: base + 'areas',
      name: 'areas',
      component: AreasView,
    },
    {
      path: base + 'area/:id',
      name: 'area',
      component: AreaView,
    },
    {
      path: base + 'investigacaoDesenvolvimento',
      name: 'investigacaoDesenvolvimento',
      component: InvestigacaoDesenvolvimentoView,
    },
    {
      path: base + 'complianceEmpresarial',
      name: 'complianceEmpresarial',
      component: ComplianceEmpresarialView,
    },
    {
      path: base + 'equipa',
      name: 'equipa',
      component: EquipaView,
    },
    {
      path: base + 'membro/:id',
      name: 'membro',
      component: EquipaMembroView,
    },
    {
      path: base + 'conhecimento',
      name: 'conhecimento',
      component: ConhecimentoView,
    },
    {
      path: base + 'eventos',
      name: 'eventos',
      component: EventosView,
    },
    {
      path: base + 'evento/:id',
      name: 'evento',
      component: EventoView,
    },
    {
      path: base + 'noticias',
      name: 'noticias',
      component: NoticiasView,
    },
    {
      path: base + 'noticia/:id',
      name: 'noticia',
      component: NoticiaView,
    },
    {
      path: base + 'videos',
      name: 'videos',
      component: VideosView,
    },
    {
      path: base + 'video/:id',
      name: 'video',
      component: VideoView,
    },
    {
      path: base + 'publicacoes',
      name: 'publicacoes',
      component: PublicacoesView,
    },
    {
      path: base + 'publicacao/:id',
      name: 'publicacao',
      component: PublicacaoView,
    },
    {
      path: base + 'newsletters',
      name: 'newsletters',
      component: NewslettersView,
    },
    {
      path: base + 'newsletter/:id',
      name: 'newsletter',
      component: NewsletterView,
    },
    {
      path: base + 'legalAlerts',
      name: 'legalAlerts',
      component: LegalAlertsView,
    },
    {
      path: base + 'legalAlert/:id',
      name: 'legalAlert',
      component: LegalAlertView,
    },
    {
      path: base + 'livros',
      name: 'livros',
      component: LivrosView,
    },
    {
      path: base + 'livro/:id',
      name: 'livro',
      component: LivroView,
    },
    {
      path: base + 'ebooks',
      name: 'ebooks',
      component: EbooksView,
    },
    {
      path: base + 'ebook/:id',
      name: 'ebook',
      component: EbookView,
    },
    {
      path: base + 'outrasPublicacoes',
      name: 'outrasPublicacoes',
      component: OutrasPublicacoesView,
    },
    {
      path: base + 'outraPublicacao/:id',
      name: 'outraPublicacao',
      component: OutraPublicacaoView,
    },
    {
      path: base + ':pathMatch(.*)*',
      name: 'notFound',
      component: NotFoundView,
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'noMatch',
      component: HomeView,
    },
    {
      path: base + 'desks',
      name: 'desks',
      component: DesksView,
    },
    {
      path: base + 'focoEmpresa',
      name: 'focoEmpresa',
      component: FocoEmpresa,
    },
    {
      path: base + 'registosNotariado',
      name: 'registosNotariado',
      component: RegistosNotariado,
    }
  ],
});
