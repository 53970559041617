<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div class="app-page-margin-withoutImage app-page-padding">
      <div class="flex flex-column align-items-center">
        <!-- <div class="mt-4"><i class="pi pi-question-circle text-orange-600 text-8xl"></i></div> -->
        <div class="mt-4 rsa-font-montserrat-bold text-2xl" style="color:#00AEEF" v-html="fc_getText(null, 'notFound_title', { upper: true })"></div>
        <div class="mt-2 text-center pageNotFound_msg" v-html="fc_getText(null, 'notFound_msg', { upper: true })"></div>
        <div class="mt-2 text-center pageNotFound_caixa" @click="fc_goRoute('home')" v-html="fc_getText(null, 'notFound_caixa', { upper: true })"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loaded: false,
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.loaded = true;
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pageNotFound_msg {
  width: 25vw;
}
.pageNotFound_caixa {
  padding: 1.5vw;
  color: var(--rsa-color);
  width: 25vw;
  background-color: black;
  cursor: pointer
}
@media screen and (max-width:992px) {
  .pageNotFound_msg {
    width: 45vw;
  }
  .pageNotFound_caixa {
    padding: 2.5vw;
    width: 40%;
  }
}
@media screen and (max-width:576px) {
  .pageNotFound_msg {
    width: 80%;
  }
  .pageNotFound_caixa {
    padding: 4vw;
    width: 60%;
  }
}
</style>
