<template>
  <div @click="mt_go()" class="item sm:flex mb-6">
    <div class="flex w-full sm:w-5 md:w-4 lg:w-7 xl:w-6">
      <div class="imagem-caixa">
        <div :class=" ((item.ajustarImagem == 'False') ? 'imagem ' : 'imagemAjustada ') + ((item.delimitarImagem == 'True') ? 'imagem-border' : '')" :style="'background-image: url(' + (item.imagemPersonalizada == null ? item.imagem : item.imagemPersonalizada ) + ');'"></div>
      </div>
    </div>
    <div class="pl-1 sm:pl-3 mt-2 sm:mt-0 flex flex-column w-full sm:w-7 md:w-8 lg:w-5 xl:w-6 ">
      <div class="text-xs rsa-font-montserrat-bold rsa-color" v-html="tipoTitle + fc_formatDate(item.dataPublicacao)"></div>
      <div class="rsa-font-montserrat-bold mt-2" v-html="item.titulo"></div>
      <div class="text-xs" v-html="item.subtitulo"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    item: Object,
    tipo: Number,
  },
  computed: {
    tipoTitle() {
      if (this.tipo == 1) {
        return this.fc_getText(null, 'eventos', { upper: true }) + ' - ';
      } else if (this.tipo == 2) {
        return this.fc_getText(null, 'noticiasImprensa', { upper: true }) + ' - ';
      } else if (this.tipo == 3) {
        return this.fc_getText(null, 'videos', { upper: true }) + ' - ';
      } else if (this.tipo == 4) {
        return this.fc_getText(null, 'publicacoes', { upper: true }) + ' - ';
      } else {
        return '';
      }
    },
  },
  watch: {},
  methods: {
    mt_go() {
      if (this.tipo == 1) {
        this.fc_goRoute('evento', { id: this.item.id });
      } else if (this.tipo == 2) {
        this.fc_goRoute('noticia', { id: this.item.id });
      } else if (this.tipo == 3) {
        this.fc_goRoute('video', { id: this.item.id });
      } else if (this.tipo == 4) {
        if (this.item.visualizacao == 2) {
          window.open(this.item.linkPublicacaoExterna, '_blank', 'noreferrer');
        } else if (this.item.visualizacao == 3) {
          this.fc_dialogPdf(this.item.anexo, this.item.anexoNome.toUpperCase());
        } else if (this.item.visualizacao == 4) {
          this.fc_dialogPlayer(this.item.titulo, this.item.linkPlayer);
        } else {
          this.fc_goRoute('publicacao', { id: this.item.id });
        }
      }
    },
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.item {
  cursor: pointer;
}
.imagem-caixa {
  width: 100%;
}
.imagem-caixa .imagem {
  width: 100%;
  padding-bottom: 52%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
.imagem-caixa .imagemAjustada {
  width: 100%;
  padding-bottom: 52%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto 100%;
  background-color: black;
}
@media screen and (max-width: 576px) {
  .imagem-caixa {
    width: 250px;
    height: 130px;
  }
  .imagem-caixa .imagem {
    width: 100%;
    height: 100%;
    left:0;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 100%;
  }
  .imagem-caixa .imagemAjustada {
    width: 100%;
    height: 100%;
    left:0;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto 100%;
    background-color: black;
  }
}
.imagem-border {
  border: 1px solid black;
}
</style>
