<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <HomeBanner />
    <HomeDestaques />
    <HomeEventos />
    <HomeFooter />
  </div>
</template>

<script>
import HomeBanner from '@/components/HomeBanner.vue';
import HomeDestaques from '@/components/HomeDestaques.vue';
import HomeEventos from '@/components/HomeEventos.vue';
import HomeFooter from '@/components/HomeFooter.vue';
export default {
  components: {
    HomeBanner,
    HomeDestaques,
    HomeEventos,
    HomeFooter,
  },
  data() {
    return {
      loaded: false,
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.loaded = true;
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped></style>
