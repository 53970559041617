<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div :class="((item.imagem != null) ? 'app-page-margin-withImage paginaContactos-withImage' : 'app-page-margin-withoutImage app-page-padding')">
      <div :class="((item.imagem != null) ? 'banner' : '') + ' w-full'" >
        <AppImagem v-if="(item.imagem != null)" class="bannerImagem" :imagem="item.imagem" titulo="" subtitulo="" />
        <div :class="((item.imagem != null) ? 'padding-bannerContacto-withImage' : 'mb-4' )">
          <div class="paddingPagConteudo">
            <div class="itemCaixa w-full">
              <div class="flex mb-4">
                <div class="text-xl rsa-font-montserrat-bold" v-html="itemCaixa.regiao.toUpperCase()"></div>
                <div v-if="(itemCaixa.parceria != null && itemCaixa.parceria != '')" class="text-sm rsa-font-montserrat-bold" v-html="fc_getText(null, 'emParceriaCom') + ' ' + itemCaixa.parceria"></div>
              </div>
              <div class="caixa-contactos flex flex-column gap-1">
                  <div v-if="(itemCaixa.moradaArteria != null && itemCaixa.moradaArteria != '')" @click="mt_openGoogleMaps()" :class="(item.linkGoogleMaps != null && item.linkGoogleMaps != '' ? ' linkGoogleMaps ' : '')">
                    <div v-html="itemCaixa.moradaArteria"></div>
                    <div v-if="((itemCaixa.moradaCp != null && itemCaixa.moradaCp != '') || (itemCaixa.moradaCpAlf != null && itemCaixa.moradaCpAlf != ''))">
                      <span v-if="(itemCaixa.moradaCp != null && itemCaixa.moradaCp != '')" v-html="itemCaixa.moradaCp"></span>
                      <span v-if="((itemCaixa.moradaCp != null && itemCaixa.moradaCp != '') && (itemCaixa.moradaCpAlf != null && itemCaixa.moradaCpAlf != ''))">&nbsp;</span>
                      <span v-if="(itemCaixa.moradaCpAlf != null && itemCaixa.moradaCpAlf != '')" v-html="itemCaixa.moradaCpAlf"></span>
                    </div>
                  </div>
                  <div class="mb-1 limiteTexto" >
                    <div v-if="(itemCaixa.telefone != null && itemCaixa.telefone != '')">
                      <span class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosTelefone') + ' '"></span>
                      <span v-html="itemCaixa.telefone"></span>
                      <span v-if="(itemCaixa.chamadaRedeFixa != null && itemCaixa.chamadaRedeFixa != false)"> *</span>
                    </div>
                    <div v-if="(itemCaixa.telemovel != null && itemCaixa.telemovel != '')">
                      <span class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosTelemovel') + ' '"></span>
                      <span v-html="itemCaixa.telemovel"></span>
                    </div>
                    <div v-if="(itemCaixa.telemovelAlternativo != null && itemCaixa.telemovelAlternativo != '')">
                      <span class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosTelemovel') + ' '"></span>
                      <span v-html="itemCaixa.telemovelAlternativo"></span>
                    </div>
                    <div v-if="(itemCaixa.fax != null && itemCaixa.fax != '')">
                      <span class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosFax') + ' '"></span>
                      <span v-html="itemCaixa.fax"></span>
                    </div>
                  </div>
                  <div v-if="(itemCaixa.email != null && itemCaixa.email != '')" class="limiteTexto">
                    <span class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosEmail') + ' '"></span>
                    <a :href="'mailto:' + itemCaixa.email" target="_blank" v-html="itemCaixa.email"></a>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="((item.imagem != null) ? ' paddingPagContactos margin-caixasContactos' : '')">
        <div class="paddingPagConteudo flex flex-column w-full gap-4">
          <!-- <div v-if="(item.linkGoogleMaps != null && item.linkGoogleMaps != '')">
            <iframe :src="item.linkGoogleMaps" frameborder="0" style="border: 0px; width: 100%; height: 300px; pointer-events: none;" allowfullscreen="true"></iframe>
          </div> -->
          <div class="flex flex-wrap mt-2 gap-4">
            <!-- <ListItemContacto :item="item" /> -->
            <ListItemContacto v-for="(forItem, forIndex) in item.redePrincipal" :key="forIndex" :item="forItem" />
          </div>
          <div class="mt-4" v-html="fc_getText(null, 'chamadaRedeFixa')"></div>
          <div v-if="(item.redeParceriaNacional != null && item.redeParceriaNacional.length > 0)" class="flex flex-column w-full mt-8">
            <AppTitleX :titulo="fc_getText(null, 'parceriasNacionais')" />
            <div class="flex flex-wrap gap-4 mt-2">
              <ListItemContacto v-for="(forItem, forIndex) in item.redeParceriaNacional" :key="forIndex" :item="forItem" />
            </div>
            <div class="mt-4" v-html="fc_getText(null, 'chamadaRedeFixa')"></div>
          </div>
          <div v-if="(item.redeParceriaInternacional != null && item.redeParceriaInternacional.length > 0)" class="flex flex-column w-full mt-8">
            <AppTitleX :titulo="fc_getText(null, 'parceriasInternacionais')" />
            <div class="flex flex-wrap gap-4 mt-2">
              <ListItemContacto v-for="(forItem, forIndex) in item.redeParceriaInternacional" :key="forIndex" :item="forItem" />
            </div>
          </div>
          <div v-if="(item.redeOutras != null && item.redeOutras.length > 0)" class="flex flex-column w-full mt-8">
            <AppTitleX :titulo="fc_getText(null, 'outrasParcerias')" />
            <div class="flex flex-wrap gap-4 mt-2">
              <ListItemContacto v-for="(forItem, forIndex) in item.redeOutras" :key="forIndex" :item="forItem" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListItemContacto from '@/components/ListItemContacto.vue';
export default {
  components: {
    ListItemContacto,
  },
  data() {
    return {
      loaded: false,
      item: {},
      itemCaixa: {}
    };
  },
  props: {},
  computed: {
    mostrarSocialMedia() {
      if (
        (this.item.linkFacebook != null && this.item.linkFacebook != '') ||
        (this.item.linkLinkedin != null && this.item.linkLinkedin != '') ||
        (this.item.linkYoutube != null && this.item.linkYoutube != '') ||
        (this.item.linkInstagram != null && this.item.linkInstagram != '')
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
  methods: {
    mt_start() {
      this.mt_loadData();
    },
    mt_loadData() {
      this.loaded = false;
      this.fc_fetch({ endpoint: 'Contactos/', method: 'GET', success: this.mt_loadData_success, error: () => { this.mt_goBack(); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.item != null) {
        this.item = response.item;
        if (this.item.redePrincipal.length > 0 ) {
          this.itemCaixa = this.item.redePrincipal.shift();
        }
        this.loaded = true;
      } else {
        this.mt_goBack();
      }
    },
    mt_goBack() {
      this.fc_goRoute('home');
    },
    mt_linkedin() {
      window.open(this.item.linkLinkedin, '_blank', 'noreferrer');
    },
    mt_youtube() {
      window.open(this.item.linkYoutube, '_blank', 'noreferrer');
    },
    mt_instagram() {
      window.open(this.item.linkInstagram, '_blank', 'noreferrer');
    },
    mt_facebook() {
      window.open(this.item.linkFacebook, '_blank', 'noreferrer');
    },
    mt_openGoogleMaps(){
      if (this.item.linkGoogleMaps != null && this.item.linkGoogleMaps != '') {
        window.open(this.item.linkGoogleMaps, '_blank', 'noreferrer');
      }
    }
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.padding-bannerContacto-withImage {
  padding: 60vh 12vw 2vw 8vw;
  z-index: 1;
  position: relative;
}
.padding-bannerContacto-withoutImage {
  padding-left: 42vw;
}
.margin-caixasContactos {
    margin-top: 10rem;
}
.bannerImagem {
  border: 0px;
  width: 100%;
  height: 70vh;
  pointer-events: none;
  position:absolute;
  z-index: 0;
}
.banner {
  height: 70vh;
  background-repeat: no-repeat;
  background-position: v-bind('position');
  background-size: cover;
}
.paddingPagContactos {
  padding: 0vw 12vw 4vw 8vw;
}
@media screen and (max-width: 768px) {
  .padding-bannerContacto-withoutImage {
    position: relative;
    padding: 0vw 0vw 8vw 0vw;
  }
  /* Proximmas classes são para tratar imagem na versão mobile como se não tivesse imagem */
  .paginaContactos-withImage {
    margin-top: 244px;
    margin-bottom: 60px;
  }
  .banner {
    height: 0vh !important;
  }
  .margin-caixasContactos {
    margin-top: 15rem;
  }
  .paddingPagContactos {
    padding: 0vw 12vw 4vw 6vw;
  }
  .padding-bannerContacto-withImage {
    padding: 0vw 12vw 4vw 6vw;
  }
}
@media screen and (min-width: 768px) {
  .itemCaixa {
    width: calc(50% + 3rem + 6vw) !important;
  }
}
@media screen and (max-width: 576px) {
  .itemCaixa {
    padding:3vw !important;
  }
}
.itemCaixa {
  background-color: black;
  padding: 1.75vw;
  color: white;
  font-size: 0.75rem;
}
.caixa-contactos {
  font-size:16px;
}
.linkGoogleMaps:hover {
  cursor: pointer;
  color: var(--rsa-color);
}
.limiteTexto {
  word-wrap: break-word;
}
</style>
