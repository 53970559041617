import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store';
import Mixin from './mixin.js';

import VueCookies from 'vue3-cookies';
import VueDayjs from 'vue3-dayjs-plugin';
import VueCountdown from '@chenfengyuan/vue-countdown';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab);

import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import BadgeDirective from 'primevue/badgedirective';
import Tooltip from 'primevue/tooltip';

import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';

import ProgressSpinner from 'primevue/progressspinner';
import DynamicDialog from 'primevue/dynamicdialog';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';

import AppSpinner from './components/AppSpinner.vue';
import AppTitleX from './components/AppTitleX.vue';
import AppTitleY from './components/AppTitleY.vue';
import AppTitleColuna from './components/AppTitleColuna.vue';
import AppImagem from './components/AppImagem.vue';
import AppCitacao from './components/AppCitacao.vue';
import MenuShare from './components/MenuShare.vue';
import MenuPrevNext from './components/MenuPrevNext.vue';
import AppTexto from './components/AppTexto.vue';

import { createHead } from '@vueuse/head';
const head = createHead();

createApp(App)
  .use(router)
  .use(store)
  .mixin(Mixin)

  .use(head)

  .use(VueCookies)
  .use(VueDayjs)
  .component(VueCountdown.name, VueCountdown)

  .component('font-awesome-icon', FontAwesomeIcon)
  .use(PrimeVue, { ripple: true }) // Ripple is an optional animation for the supported components such as buttons.

  .directive('badge', BadgeDirective)
  .directive('tooltip', Tooltip)

  .use(ConfirmationService)
  .use(DialogService)
  .use(ToastService)

  .component('ProgressSpinner', ProgressSpinner)
  .component('DynamicDialog', DynamicDialog)
  .component('ConfirmDialog', ConfirmDialog)
  .component('Toast', Toast)
  .component('Button', Button)
  .component('InputText', InputText)
  .component('Textarea', Textarea)
  .component('Dropdown', Dropdown)
  .component('FileUpload', FileUpload)

  .component('AppSpinner', AppSpinner)
  .component('AppTitleX', AppTitleX)
  .component('AppTitleY', AppTitleY)
  .component('AppTitleColuna', AppTitleColuna)
  .component('AppImagem', AppImagem)
  .component('AppCitacao', AppCitacao)
  .component('MenuShare', MenuShare)
  .component('MenuPrevNext', MenuPrevNext)
  .component('AppTexto', AppTexto)

  .mount('#app');
