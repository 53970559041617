<template>
  <div v-if="(!loaded)"><AppSpinner /></div>
  <div v-if="(loaded)">
    <div class="app-page-desks pb-8">
      <AppCitacao class="desksTitulo" :titulo="fc_getText(null, 'desksInternacionais')" />
      <div v-for="(deskItem, deskIndex) in desks.list" :key="deskIndex" :item="deskIndex">
        <div class="flex flex-column lg:flex-row w-full">
          <div class="lg:flex flex-column flex-grow-0">
            <div class="coluna">
              <div class="flex flex-column w-full mb-4 mt-2">
                <div class="deskDescritivo flex flex-column rsa-font-zilla-bold text-2xl md:text-3xl lg:text-4xl" v-html="deskItem.titulo"></div>
              </div>
              <div class="columnContactos p-4 lg:p-0 lg:pl-6">
                <div class="mt-4">
                  <div v-if="(deskItem.coordenadores != null && deskItem.coordenadores.length > 0)">
                    <div class="pb-2 rsa-font-montserrat surface-border" v-html="fc_getText(null, 'coordenacao', { title: true })"></div>
                    <div v-for="(forItem, forIndex) in deskItem.coordenadores" :key="forIndex" class="py-1 rsa-font-montserrat-bold nomeLink" v-html="forItem.nome" @click="fc_goRoute('membro', { id: forItem.id })" ></div>
                  </div>
                </div>
                <div v-if="(deskItem.coordenadores != null && deskItem.coordenadores.length > 0)" class="mt-4">
                  <div class="rsa-font-montserrat-bold" v-html="fc_getText(null, 'contactosEmail') + ' '"></div>
                  <div v-for="(forItem, forIndex) in deskItem.coordenadores" :key="forIndex" class="limiteTexto">
                    <a :href="'mailto:' + forItem.email" target="_blank" v-html="forItem.email"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-column">
            <div v-if="(deskItem.coordenadores != null && deskItem.coordenadores.length > 0)" class="coordenadoresDesk">
              <div class="flex flex-wrap gap-3 mt-4">
                <ListItemMembro v-for="(forItem, forIndex) in deskItem.coordenadores" :key="forIndex" :item="forItem" />
              </div>
            </div>
          </div>
          <div class="flex flex-column flex-grow-1">
            <div class="flex flex-column gap-4 mt-4 ml-4 mr-6">
              <div class="margin-columnContent">
                <div v-html="deskItem.conteudo"></div>
              </div>
            </div>
          </div>
        </div>
        <AppCitacao class="desksTitulo" :justLine="true" :titulo="fc_getText(null, 'desksInternacionais')" v-if="(desks.list.length > 0 && deskIndex < desks.list.length - 1)" />
      </div>
    </div>
  </div>
</template>

<script>
import ListItemMembro from '@/components/ListItemMembro.vue';
export default {
  components: {
    ListItemMembro,
  },
  data() {
    return {
      loaded: false,
      id: null,
      desks: {
        list: {}
      },
    };
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.mt_loadData();
    },
    mt_loadData() {
      this.loaded = false;
      this.fc_fetch({ endpoint: 'Desks/', method: 'GET', success: this.mt_loadData_success, error: () => { this.mt_goBack(); } });
    },
    mt_loadData_success(response) {
      if (response != null && response.list != null) {
        this.desks.list = response.list;
        this.loaded = true;
      } else {
        this.mt_goBack();
      }
    },
    mt_goBack() {
      this.fc_goRoute('home');
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.coluna {
  width: 308px;
  padding-top: 2vh;
  padding-right: 30px;
}
.limiteTexto {
  word-wrap: break-word;
}
.nomeLink:hover {
  color: var(--rsa-color);
  cursor:pointer;
}
.coordenadoresDesk {
  width:350px;
}
@media screen and (max-width: 992px) {
  .desksTitulo:deep(.text .text-titulo) > div > span {
    padding-left: 1.5rem;
  }
  .coluna {
    padding-left: 1.5rem;
  }
  .columnContactos {
    padding: 0px 0px !important;
  }
  .margin-columnContent {
    margin-right: 15px;
  }
  .app-page-desks {
    margin-top: 244px;
  }
  .coordenadoresDesk {
    width:100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
@media screen and (min-width: 992px) {
  .margin-columnContent {
    margin-right: 30px;
  }
  .app-page-desks {
    margin-top: 190px;
  }
  .desksTitulo:deep(.text .text-titulo) > div > span {
    padding-left: 310px;
  }
  .deskDescritivo {
    padding-left: 3rem;
  }
}
@media screen and (min-width: 1200px) {
  .desksTitulo:deep(.text .text-titulo) > div > span {
    padding-left: 310px;
  }
}
</style>
