<template>
  <div :class="(conteudo != null && conteudoVazio ? 'citacao ' : '') + 'mr-6'">
    <div class="flex flex-column w-full mb-4" v-if="(!justLine)">
      <div class="text w-full">
        <div class="text-titulo flex flex-column" v-html="titulo"></div>
        <div class="flex gap-0" v-if="(subtitulo != null)">
          <!-- <div class="text-titulo hidden sm:flex flex-column flex-grow-0 opacity-0" v-html="tituloLastDiv"></div> -->
          <div class="text-subtitulo flex flex-column flex-grow-1" v-html="subtitulo"></div>
        </div>
      </div>
      <div v-if="(conteudo != null && conteudoVazio)" class="text w-full flex gap-0">
        <!-- <div class="text-titulo hidden sm:flex flex-grow-0 opacity-0" v-html="tituloLastDiv"></div> -->
        <div class="conteudo pt-4 text-xl" v-html="conteudo"></div>
      </div>
    </div>
    <div class="flex flex-column w-full mb-4" v-if="(justLine)">
      <div class="text w-full">
        <div class="flex gap-0" v-if="(titulo != null)">
          <div class="text-titulo justLine sm:flex flex-column" v-html="tituloJustLine"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    titulo: String,
    subtitulo: String,
    conteudo: String,
    justLine: Boolean,
  },
  computed: {
    conteudoVazio() {
      if (this.conteudo != null){
        if (this.fc_removeHTML(this.conteudo).trim() != '') {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    tituloLastDiv() {
      const divs = this.titulo.split('<div>');
      if (divs.length > 1) {
        return '<div>' + divs[divs.length - 1] + '';
      } else {
        return this.titulo;
      }
    },
    tituloJustLine(){
      const divs = this.titulo.split('<div>');
      if (divs.length > 1) {
        return '<div style="visibility:hidden">' + divs[divs.length - 1] + '';
      } else {
        return this.titulo;
      }
    }
  },
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.citacao {
  background-image: url('@/assets/design/citacao.png');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: auto;
  min-height: 196px;
}

.text .text-titulo {
  font-family: var(--rsa-font-zilla-semibold);
  font-size: 32px;
}
@media screen and (max-width: 992px) {
  .text .text-titulo {
    font-size: 32px;
  }
}
@media screen and (min-width: 576px) {
  .text .text-titulo {
    font-size: 32px;
  }
}
@media screen and (min-width: 768px) {
  .text .text-titulo {
    font-size: 42px;
  }
}
@media screen and (min-width: 992px) {
  .text .text-titulo {
    font-size: 52px;
  }
}
@media screen and (min-width: 1200px) {
  .text .text-titulo {
    font-size: 62px;
  }
}

.text .text-titulo:deep(div) {
  margin-top: -8px;
}
.text .text-titulo:deep(div > span) {
  white-space: nowrap;
}
.text .text-titulo:deep(div:last-child > span) {
  border-bottom: 1px solid var(--text-color);
}

.text .text-subtitulo {
  padding: 10px 0px;
  font-size: 20px;
}
@media screen and (min-width: 576px) {
  .text .text-subtitulo {
    font-size: 20px;
  }
}
@media screen and (min-width: 768px) {
  .text .text-subtitulo {
    font-size: 28px;
  }
}
@media screen and (min-width: 992px) {
  .text .text-subtitulo {
    font-size: 32px;
  }
}
@media screen and (min-width: 1200px) {
  .text .text-subtitulo {
    font-size: 36px;
  }
}
.justLine {
  border-bottom: 1px solid black;
}
</style>
