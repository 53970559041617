<template>
  <div v-if="(!loaded || list.length > 0)">
    <div v-if="(!loaded)"><AppSpinner /></div>
    <div v-if="(loaded)" class="paddingPagConteudo">
      <AppTitleX :titulo="fc_getText(null, 'videos', { title: true })" />
      <div class="flex flex-wrap w-full gap-3">
        <ListItemVideo v-for="(forItem, forIndex) in list" :key="forIndex" :item="forItem" />
      </div>
      <div class="flex justify-content-end mt-4 sm:mt-8">
        <div @click="fc_goRoute('videos')" class="rsa-button-black" v-html="fc_getText(null, 'verMais')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ListItemVideo from '@/components/ListItemVideo.vue';
export default {
  components: {
    ListItemVideo,
  },
  data() {
    return {
      loaded: false,
    };
  },
  props: {
    list: Array,
  },
  computed: {},
  watch: {},
  methods: {
    mt_start() {
      this.loaded = true;
    },
  },
  created() {},
  mounted() {
    this.mt_start();
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped></style>
